import { OwcTypography } from '@one/react';
import React from 'react';
import '../styles.scss';

/**
 * Renders a read only list of data clauses
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class DataClauses extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
        if (Array.isArray(this.props.dataClauses) && this.props.dataClauses.length > 0) {
            return (
                <dl>
                    {this.props.dataClauses.map((dataClause, index) => (
                        <div key={"dataClauseDiv" + index + "-" + dataClause.dataClauseNo}
                            style={{marginBottom:"0.5em"}}>
                            <dt key={"dataClauseTitle" + index + "-" + dataClause.dataClauseNo}>
                                <b>Data Clause {index + 1}</b>
                            </dt>
                            <dd key={"dataClauseText" + index + "-" + dataClause.dataClauseNo}
                            style={{whiteSpace: "pre-wrap"}}>
                                {dataClause.dataClauseText}
                            </dd>
                        </div>
                    ))}
                </dl>
            );
        } else {
            return (
                <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px", marginBottom:"1em"}}>
                    <OwcTypography style={{fontWeight:"bold", color:"Grey"}}>No Data Clauses</OwcTypography>
                </div>
            );
        }
    }
}


export default DataClauses;
