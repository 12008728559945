import './DateFormat.js'
import {Auth, Signer} from "aws-amplify";
import {configData} from "./config.js";

/**
 * Utility functions used by several files
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */

/**
 * Formats a data value
 * @param {*} dateObject a date object, number, string or null
 * @param {*} includeTime true = date and  time, false = just date
 * @returns a formated string of the date
 */
 export function formatDate(dateObject, includeTime = false) {
  let retValue = dateObject;
  if (typeof (retValue) === "string") {
    retValue = new Date(Date.parse(retValue));
  }
  if (typeof (retValue) === "number") {
    retValue = new Date(retValue);
  }
  if (retValue instanceof Date) {
    if (includeTime)
    {
      retValue = retValue.format("dd-mmm-yyyy HH:MM:ss");
    } else {
      retValue = retValue.format("dd-mmm-yyyy");
    }

  }
  return retValue;
}


/**
 * coverts a date string to a date object
 * @param {*} dateObject a date object, number, string or null
 * @returns either null or a data object
 */
 export function convertToDate(dateObject) {
  let retValue = dateObject;
  if (typeof (retValue) === "string") {
    retValue = new Date(Date.parse(retValue));
  }
  if (typeof (retValue) === "number") {
    retValue = new Date(retValue);
  }
  if (retValue instanceof Date) {
    return retValue;
  }
  return null;
}

/**
 * Truncates the time and time zone from a date time
 * @param {*} value 
 * @returns a Date with the time and time zone truncated
 */
export function truncateDate(value) {
  return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
}

/**
 * Removes the roche_ prefix from usernames
 * @param {*} value The username
 * @returns The username with the prefix removed if present
 */
export function cleanUserName(value) {
  let retVal = value;
  const unwantedPrefix = "roche_";
  if (retVal.startsWith(unwantedPrefix)) {
    retVal = retVal.slice(unwantedPrefix.length);
  }
  return retVal;
}

export async function signRequest(url, method, service, region, data) {
  const essentialCredentials = Auth.essentialCredentials(await Auth.currentCredentials());

  const params = { data, method, url };
  const credentials = {
    secret_key: essentialCredentials.secretAccessKey,
    access_key: essentialCredentials.accessKeyId,
    session_token: essentialCredentials.sessionToken,
  };
  const serviceInfo = { region, service };

  // Signer.sign takes care of all other steps of Signature V4
  const s = Signer.sign(params, credentials, serviceInfo)
  return s
}


export async function fetchSigned(url, init={"method": "GET"}) {
  try {      
      if (!("method" in init)) {
        init.method = "GET";
      }
      let data = "";
      if ("body" in init) {
        data = init["body"];
      }
      const signedRequest = await signRequest(url, init.method,
                                              configData.AWS_SERVICE, configData.AWS_REGION,
                                              data);
      init.mode = "cors";
      init.cache = "no-cache";
      init.referrer = "client";

      if ("headers" in init) {
        init.headers = Object.assign({}, init.headers, signedRequest.headers);
      } else {
        init.headers = signedRequest.headers;
      }
      
      const response = await fetch(signedRequest.url, init);
      return response;
  } catch (e) {
      console.log(e)
  }
}

export function b64Encode(input) {
  const buff = new Buffer(input);
  return buff.toString('base64');
}

export function b64Decode(input) {
  const decodebuff = new Buffer(input, 'base64');
  return decodebuff.toString('ascii');
}
