import React from 'react';
import {configData} from "./config.js";
import './styles.scss';

import {truncateDate, fetchSigned} from "./Utilities.js"

import {
  OwcButton, OwcTypography, OwcExpandableGroup, OwcExpandable,
  OwcTextarea, OwcDatepicker, OwcInput,
  OwcIconButton, OwcTooltip, OwcCheckbox
} from '@one/react';

/**
 * The interactive form for extracting and capturing data agreement information
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class CaptureAgreement extends React.Component {
  AFFILIATE_BLANK_VALUE = "Select an Affiliate";
  VALIDATED_TEXT_CONTROLS = ["customerName", "rocheName", "title"];
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.state.affiliateList=[];
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
      this.handleCancelClick();
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
   componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredAffiliateList = result.filter(value =>
            value.type === "Affiliate"
          );
          const filteredActiveAffiliateList = filteredAffiliateList.filter(value =>
            value.isActive === true
          );
          this.setState({ affiliateList: filteredActiveAffiliateList,
            affiliateListComplete: filteredAffiliateList },
            this.handleCancelClick());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Sets the initial state
   * @returns the initial state dictionary
   */
  initialState() {
    return ({
      customerName: null,
      customerCountry: null,
      customerAddress: null,
      customerAccountNo: null,
      rocheName: null,
      rocheCountry: null,
      rocheAddress: null,
      title: null,
      validFrom: null,
      terminationDate: null,
      dataClauses: Array(1).fill({ dataClauseNo: -1, dataClauseText: null}),
      submissionState: null,
      deleteEnabled: false,
      affiliateText: this.AFFILIATE_BLANK_VALUE,
      entitlementDateValidityVaries: false,
      publishedVersionCount: 0,
      entitlementCount: 0,
    })
  }

  /**
   * Resets the form by setting the state back to the inital state
   */
  handleCancelClick() {
    //reset the state
    const newState = this.initialState();

    if (this.props.customerAgreementId !== null) {
      this.setState({
      }, () => {
        this.loadAgreementData(null, this.props.customerAgreementId)
      });
    } else {
      this.setState(newState);
    };
    this.props.onUnsavedChangesChange(false);

  }

  /**
   * Handles the change of any of the form inputs, updating the state
   * @param {*} value The new value
   * @param {string} name The name of the control who's value has changed
   */
  handleInputChange(value, name) {
    let stateObject = {};

    if (typeof (value) === "string") {
      stateObject[name] = value.trim();
    } else {
      if (Array.isArray(value) && !value.length) {
        stateObject[name] = null;
      } else {
        stateObject[name] = value;
      }
    }

    // the form has been changed clear any submission state
    if (stateObject[name] !== this.state[name]) {
      stateObject["submissionState"] = "Unsaved changes, click Save Changes to save";
      this.props.onUnsavedChangesChange(true);
    }
    this.setState(stateObject);
    

    // check validation
    if (this.VALIDATED_TEXT_CONTROLS.includes(name)) {
      this.checkTextValidation(value, name);
    }
  }

  /**
   * Handles updates to the affilite dropdown
   * @param {*} ev 
   */
  handleAffiliateChange(ev){
    if (ev.target.value !== this.state.affiliateText) {
      this.setState({ affiliateText: ev.target.value});
    }
  }

  /**
   * Handles the state updates for Data Clause entries
   * @param {string} value The new text from the data clause control  
   * @param {number} index The index number of the data clause control
   */
  handleDataClauseChange(value, index) {
    var newDataClauses = this.state.dataClauses.slice();
    newDataClauses[index].dataClauseText = String(value).trim();
    if (!("createdBy" in newDataClauses[index])) {
      newDataClauses[index].createdBy = this.props.userName;
    }
    this.setState({
      dataClauses: newDataClauses,
      submissionState: "Changes have been made, click Save Changes to save this agreement"
    });
    this.props.onUnsavedChangesChange(true);
  }

  /**
   * Check the validation of the text input and sets the validation state and message
   * @param {string} text The text in the control
   * @param {string} controlName The name of the control
   */
  checkTextValidation(text, controlName) {
    let errorMessage = "Required";

    if ((text !== null) && (text.trim().length > 0)) {
      // alpha characters
      const testReAlpha = /[a-zA-Z]+/;
      if (testReAlpha.test(text)) {
        errorMessage = null;
      } else {
        errorMessage = "The entry must include letters"
      };
    };

    return errorMessage;
  }

  /**
   * checks the date validation for the validFrom and terminationDate fields
   * @returns true if the validation warning should be displayed
   */
   checkDateWarning() {
    // termination date
    if ((this.state.terminationDate !== null) && (this.state.terminationDate.length !== 0)) {
      if ((this.state.validFrom !== null) && (this.state.validFrom.length !== 0)) {
        if (this.state.terminationDate < this.state.validFrom) {
          return true;
        }
      }
    }
    return false;
  }




  /**
   * Adds a new data clause control when the "Add data clause" button is pressed
   */
  handleAddDataClauseClick() {
    //take a copy of the dataclauses
    const newDataClauses = this.state.dataClauses.slice();
    newDataClauses.push({ dataClauseNo: -1, dataClauseText: null, createdBy: this.props.userName });
    this.setState({ dataClauses: newDataClauses });
  }

  /**
   * Removes a data clause
   * @param {*} index the index of the dataclause to remove
   */
  handleRemoveDataClause(index) {
    const newDataClauses = this.state.dataClauses.slice();

    if ((newDataClauses[index].dataClauseNo !== null) &&
      (newDataClauses[index].dataClauseNo >= 0)) {
      // This has been saved to the database, mark it for deletion
      newDataClauses[index].isDeleted = true;
      this.props.onUnsavedChangesChange(true);
      this.setState({ dataClauses: newDataClauses,
                      submissionState: "Changes have been made, click Save Changes to save this agreement" });
    } else {
      //not added yet, just remove it
      newDataClauses.splice(index, 1);
      this.setState({ dataClauses: newDataClauses });
    }
  }

  /**
   * Enables or disables the delete button
   * @param {*} ev 
   */
  handleDeleteCheckboxClick(ev) {
    this.setState({ deleteEnabled: ev.detail });
  }

  /**
   * Enables or disables the delete button
   * @param {*} ev 
   */
    handleDateValidityCheckboxChange(ev) {
      this.setState({ entitlementDateValidityVaries: ev.detail });
    }

  /**
   * Deletes the currently selected agreement
   * @param {*} ev 
   */
  handleDeleteClick(ev) {
    const submitForm = () => {
      return fetchSigned(configData.CONTRACTS_API_URL + this.props.customerAgreementId + "/", {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 204) // done successfully
          {
            // reset the form
            this.setState(this.initialState);
            this.setState({ submissionState: "Successfully Deleted" });
            this.props.onUnsavedChangesChange(false);
            this.props.onAgreementListChanged(null);
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              this.setState({ submissionState: "Error saving " + json });
            }).catch((error) => {
              console.error(error);
              this.setState({ submissionState: "Error saving " + error });
            });
          }

        });
    };
    submitForm();
  }

  /**
   * checks all of the values
   * @returns an error messge detailing all those that are not true
   */
  CheckFormValidation() {
    // force the valiadation of all the controls
    let errorString = "";
    this.VALIDATED_TEXT_CONTROLS.forEach((controlName) => { 
      const errorMessage = this.checkTextValidation(this.state[controlName], controlName); 
      if (errorMessage !== null) {
        errorString = errorString + ", " + this.convertCamelCaseToSentenceCase(controlName);
      };
    })

    if (errorString.length > 0) {
      errorString = "Error - the following fields are invalid: " + errorString.slice(1);
    };

    return errorString;
  }

  /**
   * Converts a camelCase string to Sentence Case
   * @param {*} inputText The input text in camelCase
   */
  convertCamelCaseToSentenceCase(inputText) {
    const result = inputText.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  /**
   * Validates the form and submits it to the API if valid
   * @returns nothing
   */
  handleSubmitClick() {
    // check the form is valid
    const errorMessage = this.CheckFormValidation();
    if (errorMessage) {
      this.setState({ submissionState: errorMessage });
      return;
    }

    const submissionData = this.extractFormData();
    console.log(submissionData)

    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      const isUpdate = (submissionData.customerAgreementId !== null);
      const url = isUpdate ?
        configData.CONTRACTS_API_URL + submissionData.customerAgreementId + "/" :
        configData.CONTRACTS_API_URL;
      const method = isUpdate ? 'PUT' : 'POST';
      return fetchSigned(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
        .then((response) => {
          console.log(response);
          if (response.status === 201) // inserted successfully
          {
            response.json().then((json) => {
              // load the data back from the database to update all fields and ids
              this.loadAgreementData(null, json["customerAgreementId"], false);
              this.props.onAgreementListChanged(json["customerAgreementId"]);
              this.props.onUnsavedChangesChange(false);
              this.setState({ submissionState: "Agreement Successfully Saved" });
              console.log("API Response" + JSON.stringify(json));
            }).catch((error) => {
              this.setState({ submissionState: "Error saving agreement " + error });
              console.error(error);
            });
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
                this.setState({ submissionState: "Error: This agreement has already been entered." });
              } else {
                this.setState({ submissionState: "Error saving agreement " + json.errorText });
              }
            }).catch((error) => {
              this.setState({ submissionState: "Error saving agreement " + error })
              console.error(error);
            });
          }

        });
    };
    submitForm();
  }

  /**
   * Extracts the form data to submit to the API from the state
   * @returns The extracted form data
   */
  extractFormData() {
    const submissionData = { ...this.state };
    //remove the bits we don't want to include
    delete submissionData["deleteEnabled"];
    delete submissionData["affiliateList"];
    delete submissionData["affiliateListComplete"];
    delete submissionData["affiliateText"];
    delete submissionData["submissionState"];

    submissionData.customerAgreementId = this.props.customerAgreementId;
    submissionData.createdBy = this.props.userName;

    //find the rocheAffiliateId
    if (this.state.affiliateText === this.AFFILIATE_BLANK_VALUE)
    {
      submissionData.rocheAffiliateId = null;
    } else {
      // remove the deprecated suffix if it is added
      const searchText = this.state.affiliateText.endsWith(configData.REFDATA_DEPRECATED)? 
                          this.state.affiliateText.slice(0, -configData.REFDATA_DEPRECATED.length)
                          : this.state.affiliateText;
      submissionData.rocheAffiliateId = this.getIdForRefData(searchText, this.state.affiliateList);
      if (submissionData.rocheAffiliateId === null)
      {
        submissionData.rocheAffiliateId = this.getIdForRefData(searchText, this.state.affiliateListComplete);
      }
    }
    

    // more cleaning
    // truncate the time part of the dates
    if (submissionData.validFrom !== null) {
      if (submissionData.validFrom instanceof Date) {
        submissionData.validFrom = truncateDate(submissionData.validFrom);
      } else if (typeof (submissionData.validFrom) === "number") {
        submissionData.validFrom = new Date(submissionData.validFrom)
      }
    }
    if (submissionData.terminationDate !== null) {
      if (submissionData.terminationDate instanceof Date) {
        submissionData.terminationDate = truncateDate(submissionData.terminationDate);
      } else if (typeof (submissionData.terminationDate) === "number") {
        submissionData.terminationDate = new Date(submissionData.terminationDate)
      }
    }

    return submissionData;
  }

  getIdForRefData(value, refdataList) {
    let retVal = null;
    if (refdataList){
      const refDataItem = refdataList.find(({ description }) => description === value);
      //get the record
      if (refDataItem !== undefined) {
        retVal = refDataItem.refListId;
      }
    }
    return retVal;
  }

  getDescriptionForRefData(value, refdataList) {
    let retVal = null;
    if (refdataList !== undefined) {
      const refDataItem = refdataList.find(({ refListId }) => refListId === value);
      //get the record
      if (refDataItem !== undefined) {
        retVal = refDataItem.description;
      }
    }
    return retVal;
  }


  /**
   * handles the row click event from the agreementselection control
   * @param {*} ev the event
   * @param {*} agreementId  the selected agreeemntId
   */
  loadAgreementData(ev, agreementId, clearSubmissionState = true) {
    console.log("selected agreement id: " + agreementId);

    fetchSigned(configData.CONTRACTS_API_URL + agreementId + "/")
      .then(res => res.json())
      .then(
        (result) => {
          const row = result[0];
          console.log(row);
          const validFrom = Date.parse(row.validFrom);
          const terminationDate = Date.parse(row.terminationDate);
          const newState = this.initialState();
          if (!clearSubmissionState) {
            // don't overwrite the submission state
            delete newState["submissionState"];
          }
          newState.customerName = row.customerName;
          newState.customerCountry = row.customerCountry;
          newState.customerAddress = row.customerAddress;
          newState.customerAccountNo = row.customerAccountNo;
          newState.rocheName = row.rocheName;
          newState.rocheCountry = row.rocheCountry;
          newState.rocheAddress = row.rocheAddress;
          newState.rocheAffiliateId = row.rocheAffiliateId;
          if (row.rocheAffiliateId === null) {
            newState.affiliateText = this.AFFILIATE_BLANK_VALUE;
          } else {
            newState.affiliateText = this.getDescriptionForRefData(row.rocheAffiliateId, this.state.affiliateList);
            if (newState.affiliateText === null){
              //the value is now obsolete
              newState.affiliateText = this.getDescriptionForRefData(row.rocheAffiliateId, this.state.affiliateListComplete) 
                                        + configData.REFDATA_DEPRECATED;
            }
          }
          newState.title = row.title;
          newState.validFrom = isNaN(validFrom) ? null : validFrom;
          newState.terminationDate = isNaN(terminationDate) ? null : terminationDate;
          newState.entitlementDateValidityVaries = row.entitlementDateValidityVaries;
          newState.dataClauses = row.dataClauses;
          newState.publishedVersionCount = row.publishedVersionCount;
          newState.entitlementCount = row.entitlementCount;
          this.setState(newState,
            this.props.onUnsavedChangesChange(false));
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      );
  }

  /**
   * Renders a text input control with validation
   * @param {*} label The human readable label to use
   * @param {*} controlName the name of the control in the state
   * @param {*} maxLength the mas length of the data input
   * @param {*} assistiveText the assistive text to include
   * @returns The JSX of the controls
   */
  renderTextInput(label, controlName, maxLength = 255, assistiveText = "") {
    let errorMessage = null;
    if (this.VALIDATED_TEXT_CONTROLS.includes(controlName)) {
      errorMessage = this.checkTextValidation(this.state[controlName],controlName);
    }
    
    const additionalSlots = () => {
      if (errorMessage !== null) {
        return (<span key={"inputerror" + controlName} slot="error-text">{errorMessage}</span>);
      }
      else {
        return (<span key={"inputassistive" + controlName} slot="assistive-text">{assistiveText}</span>);
      }
    }

    return (
      <OwcInput key={"input" + controlName} style={{ width: "100%" }}
        label={label} value={this.state[controlName]}
        onInputChange={(ev) => this.handleInputChange(ev.detail, controlName)}
        error={(errorMessage !== null)}
        maxLength={maxLength}>
        {additionalSlots()}
      </OwcInput>
    );
  }

  /**
   * Renders the data clauses controls
   * @returns The JSX of the controls
   */
  renderDataClauses() {
    // create the right number of dataClauses
    // set the size of the box to match the contents
    if (this.state.dataClauses && this.state.dataClauses.length > 0) {
      return (
        <div>
          {this.state.dataClauses.map((value, index) => this.renderDataClause(value, index))}
        </div>
      )
    } else {
      return (
        <div style={{marginBottom:"0.5em"}}>
          <OwcTypography style={{fontWeight:"bold"}}>No Data Clauses</OwcTypography>
        </div>
      );
    }
  }

  renderDataClause(value, index) {
    if ("isDeleted" in value) {
      // this one is deleted do not render it
      return
    } else {
      return (
        <div key={"DataClauseDiv" + index} style={{ display: "flex", flexDirection: "row", verticalAlign: "top"}}>
          <OwcTextarea
            key={"DataClause" + index}
            style={{ display: "inline-block", marginRight: "1%", paddingBottom:"1em" }}
            rows={value.dataClauseText == null ? 4 : Math.max(4, value.dataClauseText.split(/\r\n|\r|\n/).length)}
            cols="100"
            label={"Data Clause " + (index + 1) + " Text"}
            value={value.dataClauseText} onInputChange={(ev) => this.handleDataClauseChange(ev.detail, index)}
            assistiveText={"Copy / Paste relevant sections from the agreement in the original language"}
          />
          <OwcIconButton id={"DataClauseCancelBtn" + index}
            key={"DataClauseCancelBtn" + index}
            flat icon="cancel" style={{ display: "inline-block", verticalAlign: "top" }}
            onclick={() => this.handleRemoveDataClause(index)} />
          <OwcTooltip key={"DataClauseCancelBtnToolTip" + index}
            anchor={"DataClauseCancelBtn" + index}
            placement="right">Remove this data clause
          </OwcTooltip>
        </div>
      );
    }
  }

  /**
   * Renders the delete button if in edit mode
   * @returns The JSX of the controls
   */
  renderDeleteOption() {
    if (this.props.customerAgreementId !== null) {
      if (this.state.publishedVersionCount > 0 && this.props.dataSteward === false) {
        return (
          <td style={{verticalAlign:"top", textAlign:"center"}} align="centre">
                <OwcTypography variant="caption" style={{textAlign:"center"}}>Only Data Stewards can <br/>delete published agreements</OwcTypography>
          </td>
        );
      }
      else {
        let warningText = "";
        if (this.state.publishedVersionCount > 0){
          warningText = "this agreement is published";
        } else if (this.state.entitlementCount > 0) {
          warningText = "this agreement has entitlements defined";
        }
        return (
          <td style={{verticalAlign:"top"}} align="centre">
            <OwcButton style={{ width: "fit-content", marginBottom:"0.65em"}}
              key="DeleteButton"
              onclick={() => this.handleDeleteClick()} disabled={this.state.deleteEnabled ? false : true}
            >
              Delete Agreement
            </OwcButton>
            <OwcCheckbox
              key="DeleteButtonCheckbox"
              checked={this.state.deleteEnabled}
              onInputChange={(ev) => this.handleDeleteCheckboxClick(ev)}>
                <OwcTypography variant="caption">Enable Deletion</OwcTypography>
              </OwcCheckbox> 
              {warningText !== ""?
                <OwcTypography variant="caption" style={{color:"orange"}}>Warning: {warningText}</OwcTypography>
                : ""
              }
          </td>
        );
      }
    }
  }


  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    return (
      <div className="ExtractAndCaptureAgreement">
        <br/>
        <OwcExpandableGroup multiple>
          <OwcExpandable variant="standard" round expanded>
            <span slot="title">Agreement</span>
            <span slot="content">
              {this.renderTextInput("Title", "title", 255, "Required")}
              <br />
              <OwcTypography variant="caption">Valid From</OwcTypography>
              <OwcDatepicker autoClose compact label="Valid From"
                value={this.state.validFrom} format={"dd-MMM-yyyy"}
                onValueChange={(ev) => this.handleInputChange(ev.detail, "validFrom")}
              >
                <span slot="assistive-text"></span>
                <span  slot="error-text"></span>
              </OwcDatepicker>
              {this.checkDateWarning()?
              <OwcTypography style={{"color":"orange"}}variant="caption">Valid From is less than Termination Date</OwcTypography> :
              "" }
              <br />
              <div style={{display:'flex', flexDirection:'row'}}>
                <div>
                  <OwcTypography variant="caption">Termination Date</OwcTypography>
                  <OwcDatepicker autoClose compact label="Termination Date"
                    value={this.state.terminationDate} format={"dd-MMM-yyyy"}
                    onValueChange={(ev) => this.handleInputChange(ev.detail, "terminationDate")}
                  >
                    <span slot="assistive-text"></span>
                    <span slot="error-text"></span>
                  </OwcDatepicker>
                  <br />
                </div>
                <div style={{marginLeft:"1em"}}>
                  <OwcCheckbox style={{marginTop:"1.5em"}}
                    onInputChange={(ev) => this.handleDateValidityCheckboxChange(ev)}
                   checked={this.state.entitlementDateValidityVaries}>
                    <OwcTypography variant="caption">Some data entitlements are valid beyond the agreement termination date</OwcTypography>
                  </OwcCheckbox>
                </div>
              </div>
            </span>
          </OwcExpandable>
          <OwcExpandable variant="standard" round expanded >
            <span slot="title">Customer party as written in the agreement</span>
            <span slot="content">
              {this.renderTextInput("Name", "customerName", 255, "Required")}
              <br />
              {this.renderTextInput("Address", "customerAddress", 8000)}
              <br />
              {this.renderTextInput("Country", "customerCountry", 255)}
              <br />              
              {this.renderTextInput("Account Number", "customerAccountNo", 255, "If available in contract")}
              <br />
            </span>
          </OwcExpandable>
          <OwcExpandable variant="standard" round expanded>
            <span slot="title">Roche party as written in the agreement</span>
            <span slot="content">
              {this.renderTextInput("Name", "rocheName", 255, "Required")}
              <br />
              {this.renderTextInput("Address", "rocheAddress", 8000)}
              <br />
              {this.renderTextInput("Country", "rocheCountry", 255)}
              <br />
              <OwcTypography variant="caption">Affiliate</OwcTypography>
              <select style={this.state.affiliateText === this.AFFILIATE_BLANK_VALUE?
                              { width: "100%", paddingLeft:"1em", color:"grey" }:
                              { width: "100%", paddingLeft:"1em" }}
                value={this.state.affiliateText}
                onChange={(ev) => this.handleAffiliateChange(ev)}
              >
                <option key="AgreementAffiliateBlank" style={{color:"grey"}}>{this.AFFILIATE_BLANK_VALUE}</option>
                { this.state.affiliateText !== null && this.state.affiliateText.endsWith(configData.REFDATA_DEPRECATED) 
                  ? <option key="AgreementAffiliateDeptecated" style={{color:"black"}}>{this.state.affiliateText}</option>
                  : "" 
                }
                {this.state.affiliateList.map(item => (
                  <option key={"AgreementAffiliate" + item.refListId} style={{color:"black"}}>{item.description}</option>
                ))}
              </select>
            </span>
          </OwcExpandable>
          <OwcExpandable variant="standard" round expanded>
            <span slot="title">Provide all data clauses as written in the agreement</span>
            <span slot="content">
              {this.renderDataClauses()}
              <OwcButton style={{ width: "fit-content" }}
                onclick={() => this.handleAddDataClauseClick()}
              >Add data clause</OwcButton>
            </span>
          </OwcExpandable>
        </OwcExpandableGroup>
        <span>
          <table width="100%">
            <tbody>
              <tr>
                <td style={{verticalAlign:"top"}} align="left">
                  <OwcButton style={{ width: "fit-content" }}
                    onclick={() => this.handleCancelClick()}
                  >
                    Clear Unsaved Changes
                  </OwcButton>
                </td>
                {this.renderDeleteOption()}
                <td style={{verticalAlign:"top"}} align="right">
                  <OwcButton style={{ width: "fit-content" }}
                    onclick={() => this.handleSubmitClick()} disabled={this.state.submissionState === "Saving ..." ? true : false}
                  >
                    {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                  </OwcButton>
                </td>
              </tr>
            </tbody>
          </table>
          <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
            {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
          </OwcTypography>
        </span>

      </div>
    );
  }
}

export default CaptureAgreement;
