import React from 'react';
import {configData} from "./config.js";
import './styles.scss';
import {fetchSigned} from "./Utilities.js"
import DataClauses from "./components/DataClauses.js";
import {
    OwcButton, OwcExpandableGroup, OwcExpandable,
    OwcTextarea, OwcIconButton, OwcTooltip,
    OwcTypography
} from '@one/react';
//import { configure } from '@testing-library/react';

/**
 * The interactive form for translating and structureing data agreement information
 * into pairs of terms of a data categroy and either a processing action or a data use
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class TranslateAndStructure extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            dataClauses: [],
            selectedAgreementId: null,
            pairs: [],
            pairsToDelete: [],
            AgreementSelectionLoaded: false,
            submissionState: null,
        };
    }

    /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
            this.updateForm(this.props.customerAgreementId);
        }
    }

    /**
     * Runs one after construction after everything is initialised
     */
    componentDidMount() {
        this.updateForm(this.props.customerAgreementId, true);
    }


    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    /**
     * Forces the current rows to store to the database and refresh
     * @param {*} ev the event object
     */
    handleSubmitClick(ev) {
        if (this.state.selectedAgreementId !== null) {
            // save any unsaved pairs, unsaved pairs have -ve termPairNos
            this.saveTermPairs();
            // allow a few milliseconds for the updates
            const sleepDuration = 500;
            // Load up any pairs currently set in the DB for the new agreement
            this.sleep(sleepDuration).then(r => {
                this.updateForm(this.state.selectedAgreementId, true);
            });
        }
    }

    /**
     * Forces the current rows to refresh from the database
     * @param {*} ev the event object
     */
    handleCancelClick(ev) {
        if (this.state.selectedAgreementId !== null) {
            this.setState({ pairsToDelete: [], submissionState: null },
                this.loadTermPairs(this.state.selectedAgreementId));
        }
    }


    handleOnLoaded() {
        this.setState({ AgreementSelectionLoaded: true })
    }

    /** Handles the updating the form for a new agreement
     * @param {number} agreementId the selected agreement id
     */
    updateForm(agreementId, forceUpdate = false) {
        // if the agreementId is changed then
        if (forceUpdate || (agreementId !== this.state.selectedAgreementId)) {
            this.loadDataClauses(agreementId);
            this.setState({
                selectedAgreementId: agreementId
            }, this.loadTermPairs(agreementId))


            if (forceUpdate === false) {
                this.setState({ submissionState: null });
                this.props.onUnsavedChangesChange(false);
            }
        }
    }

    /** Saves the current state term pairs
     */
    saveTermPairs() {
        // first check if there is any work to do
        let itemsToSave = 0;
        for (const pair of this.state.pairs) {
            if (("changed" in pair)) {
                itemsToSave++;
            }
        }
        itemsToSave += this.state.pairsToDelete.length

        if (itemsToSave === 0) {
            this.setState({ submissionState: "Nothing to Save" });
            this.props.onUnsavedChangesChange(false);
        }
        else {

            this.setState({ submissionState: "Saving ..." });

            const pairs = this.state.pairs.slice();
            for (const pair of pairs) {
                if ((pair.termPairingNo < 0) && ("changed" in pair)) {
                    this.insertPair(pair);
                }
                if ((pair.termPairingNo > 0) && ("changed" in pair)) {
                    this.updatePair(pair);
                }
            }
            const pairsToDelete = this.state.pairsToDelete.slice();
            for (const pair of pairsToDelete) {
                this.deletePair(pair);
            }

            //set the new state
            this.setState({
                pairs: pairs,
                pairsToDelete: []
            })
        }
    }

    /**
     * Inserts a new term pair into the api
     * @param {*} pair 
     */
    insertPair(pair) {
        const submitForm = () => {
            return fetchSigned(configData.TERMPAIRS_API_URL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pair)
            })
                .then((response) => {
                    console.log(response.status);
                    if (response.status === 201) // inserted successfully
                    {
                        response.json().then((json) => {
                            console.log("API Response" + JSON.stringify(json));
                            this.setState({ submissionState: "Successfully Saved" });
                            this.props.onUnsavedChangesChange(false); 
                        }).catch((error) => {
                            this.setState({ submissionState: "Error saving " + error });
                            console.error(error);
                        });
                    } else {
                        response.json().then((json) => {
                            let errorMessage = "Error Saving "
                            if (json.errorText) {
                                errorMessage += json.errorText;
                                if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
                                    errorMessage = "Error - Could not save a duplicate record";
                                }
                            }
                            this.setState({ submissionState: errorMessage });
                            console.log("API Response" + JSON.stringify(json));
                        }).catch((error) => {
                            this.setState({ submissionState: "Error saving " + error });
                            console.error(error);
                        });
                    }

                });
        };
        submitForm();
    }

    /**
     * Updates a new term pair into the api
     * @param {*} pair 
     */
    updatePair(pair) {
        const submitForm = () => {
            return fetchSigned(configData.TERMPAIRS_API_URL + pair.termPairingNo + "/", {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pair)
            })
                .then((response) => {
                    console.log(response.status);
                    if (response.status === 201) // inserted successfully
                    {
                        response.json().then((json) => {
                            console.log("API Response" + JSON.stringify(json));
                            this.setState({ submissionState: "Successfully Saved" });
                            this.props.onUnsavedChangesChange(false);
                        }).catch((error) => {
                            console.error(error);
                            this.setState({ submissionState: "Error saving " + error });
                        });
                    } else {
                        response.json().then((json) => {
                            console.log("API Response" + JSON.stringify(json));
                            let errorMessage = "Error Saving"
                            if (json.errorText) {
                                errorMessage += json.errorText;
                                if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
                                    errorMessage = "Error - Could not save a duplicate record";
                                }
                            }
                            this.setState({ submissionState: errorMessage });
                        }).catch((error) => {
                            console.error(error);
                            this.setState({ submissionState: "Error saving " + error });
                        });
                    }

                });
        };
        submitForm();
    }

    /**
     * deletes a term pair in the api
     * @param {*} pair 
     */
    deletePair(pair) {
        const submitForm = () => {
            return fetchSigned(configData.TERMPAIRS_API_URL + pair.termPairingNo + "/", {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    console.log(response.status);
                    if (response.status === 204) // done successfully
                    {
                        this.setState({ submissionState: "Successfully Saved" });
                        this.props.onUnsavedChangesChange(false);
                    } else {
                        response.json().then((json) => {
                            console.log("API Response" + JSON.stringify(json));
                            this.setState({ submissionState: "Error saving " + json });
                        }).catch((error) => {
                            console.error(error);
                            this.setState({ submissionState: "Error saving " + error });
                        });
                    }

                });
        };
        submitForm();
    }

    /** Loads term paires from the API
     * @param {number} agreementId The selected agreement ID
     */
    loadTermPairs(agreementId) {
        fetchSigned(configData.TERMPAIRS_API_URL + agreementId + "/")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        pairs: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    /** Loads data clauses from the API
         * @param {number} agreementId The selected agreement ID
         */
    loadDataClauses(agreementId) {
        fetchSigned(configData.CONTRACTS_API_URL + agreementId + "/")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        dataClauses: result[0].dataClauses
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    handleAddPairsClick(pairType) {
        const newPairs = this.state.pairs.slice();
        const pairNo = -(newPairs.length + 1);
        newPairs.push({
            customerAgreementId: this.state.selectedAgreementId,
            termPairingNo: pairNo,
            pairingType: pairType,
            dataCategoryText: null,
            dataUsageText: null,
            createdBy: this.props.userName,
        })
        this.setState({ pairs: newPairs });
    }

    handleRemovePairClick(selectedAgreementId, selectedTermPairingNo) {
        const newPairs = this.state.pairs.slice();
        const newPairstoDelete = this.state.pairsToDelete.slice();
        const index = newPairs.findIndex(({ customerAgreementId, termPairingNo }) => customerAgreementId === selectedAgreementId &&
            termPairingNo === selectedTermPairingNo);
        let newSubmissionState = this.state.submissionState;
        //get the index
        if (index > -1) {
            const pairToDelete = newPairs.splice(index, 1)
            if (pairToDelete[0].termPairingNo > 0) {
                // this is already saved into the database
                newPairstoDelete.push(pairToDelete[0]);
                newSubmissionState = "Unsaved changes, click Save Changes to save";
                this.props.onUnsavedChangesChange(true);
            }
        }
        this.setState({
            pairs: newPairs,
            pairsToDelete: newPairstoDelete,
            submissionState: newSubmissionState,
        });
    }

    handleInputChange(value, field, selectedAgreementId, selectedTermPairingNo) {
        const newPairs = this.state.pairs.slice();
        const result = newPairs.find(({ customerAgreementId, termPairingNo }) => customerAgreementId === selectedAgreementId &&
            termPairingNo === selectedTermPairingNo);

        if (result[field] !== value) {
            result[field] = value;

            result["changed"] = true
            this.setState({ pairs: newPairs, submissionState: "Unsaved changes, click Save Changes to save" });
            this.props.onUnsavedChangesChange(true);
        }
    }

    /**calculates how many rows the text controls need to display the text correctly
     *
     * @param {*} text the text to display
     * @param {*} cols the number of characters that can be displayed in one line
     */
    determineRowsToDisplay(text, cols) {
        let retVal = 1;
        if (text === null){
            return retVal;
        }

        //count the number of newlines
        const lines = text.split(/\r\n|\r|\n/)
        retVal = Math.max(1,lines.length);

        lines.forEach(line => {
            retVal += Math.floor(line.length / cols);
        })
        return retVal;
    }

    renderPairs() {
        return (
            <div>
                {this.state.pairs.map(pair => (
                    <div key={"pairDiv" + pair.customerAgreementId + "-" + pair.termPairingNo}>
                        <b style={{ width: "10%", marginRight: "1em", display: "inline-block", verticalAlign: "top" }}>
                            {pair.pairingType}
                        </b>
                        <OwcTextarea style={{ display: "inline-block", marginRight: "1%" }}
                            id={"pairdataCategory" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            key={"pairdataCategory" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            label="Data Category" value={pair.dataCategoryText}
                            cols="20" rows={this.determineRowsToDisplay(pair.dataCategoryText,20)}
                            onInputChange={(ev) => this.handleInputChange(ev.detail, "dataCategoryText", pair.customerAgreementId, pair.termPairingNo)}
                            required assistiveText="Required (in english)" validation-mode="completion"
                            maxLength={255} minLength={1}
                        />
                        <OwcTooltip key={"pairdataCategoryToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            anchor={"pairdataCategory" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            placement="right">The type of data defined in the agreement:  e.g. “service data”, “patient data”, “sensitive data”, “non-personal data”, ...
                        </OwcTooltip>
                        <OwcTextarea style={{ display: "inline-block", marginRight: "1%" }}
                            id={"pairdataUsage" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            key={"pairdataUsage" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            label={pair.pairingType} value={pair.dataUsageText}
                            cols="40" rows={this.determineRowsToDisplay(pair.dataUsageText,40)}
                            onInputChange={(ev) => this.handleInputChange(ev.detail, "dataUsageText", pair.customerAgreementId, pair.termPairingNo)}
                            required assistiveText="Required (in english)" validation-mode="completion"
                            maxLength={255} minLength={1}
                        />
                        <OwcTooltip key={"pairdataUsageToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            anchor={"pairdataUsage" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            placement="right">{pair.pairingType === configData.ENTITLEMENT_DATA_USE ?
                                `data use: “improve Roche’s services”, “prepare for pandemic”, “monitor field operations”
                                                        ... as defined in the agreement` :
                                `data processing action: “collect”, “use”, “store”, “process”, “edit”, ... as defined in the agreement`}
                        </OwcTooltip>
                        <OwcIconButton id={"pairCancelBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            key={"pairCancelBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            flat icon="cancel" style={{ display: "inline-block", verticalAlign: "top" }}
                            onclick={() => this.handleRemovePairClick(pair.customerAgreementId, pair.termPairingNo)} />
                        <OwcTooltip key={"pairCancelBtnToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            anchor={"pairCancelBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                            placement="right">Remove this {pair.pairingType}
                        </OwcTooltip>
                    </div>
                ))}
            </div>
        )
    }

    renderAddPairsButtons() {
        return (
            <div>
                <OwcButton style={{ width: "fit-content", display: "inline-block" }}
                    onclick={() => this.handleAddPairsClick(configData.ENTITLEMENT_PROCESSING_ACTION)}
                >
                    Add data processing action
                </OwcButton>
                <OwcButton style={{ width: "fit-content", display: "inline-block", marginLeft: "10px" }}
                    onclick={() => this.handleAddPairsClick(configData.ENTITLEMENT_DATA_USE)}
                >
                    Add data use
                </OwcButton>
            </div>
        )
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
        let messageColour = "black";
        if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
            messageColour = "red";
        };

        if (this.props.customerAgreementId === null) {
            return (
                <OwcTypography variant="title6">Select an agreement to edit</OwcTypography>
            );
        } else {
            return (
                <div className="TranslateAndStructure">
                    <OwcExpandableGroup multiple>
                        <OwcExpandable key="dataClauses" variant="standard" round expanded >
                            <span key="dataClausesTitle" slot="title">Agreement Data Clauses</span>
                            <span key="dataClausesContent" slot="content">
                                {this.state.selectedAgreementId === null ? "" : <DataClauses dataClauses={this.state.dataClauses}/>}
                                {this.state.pairs.length === 0 ? "" : this.renderPairs()}
                                {this.state.selectedAgreementId === null ? "" : this.renderAddPairsButtons()}

                            </span>
                        </OwcExpandable>
                    </OwcExpandableGroup>
                    <span>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td align="left">
                                        <OwcButton style={{ width: "fit-content" }}
                                            onclick={() => this.handleCancelClick()}
                                        >
                                            Clear Unsaved Changes
                                        </OwcButton>
                                    </td>
                                    <td align="right">
                                        <OwcButton style={{ width: "fit-content" }}
                                            onclick={() => this.handleSubmitClick()} disabled={this.state.submissionState === "Saving ..." ? true : false}
                                        >
                                            {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                                        </OwcButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
                            {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
                        </OwcTypography>
                    </span>
                </div>
            );
        }
    }
}


export default TranslateAndStructure;
