import React from 'react';
import './styles.scss';
import { configData } from "./config.js";
import CaptureAgreement from './CaptureAgreement';
import TranslateAndStructure from './TranslateAndStructure';
import Harmonize from './Harmonize';
import ReviewAndPublish from './ReviewAndPublish';
import ReferenceLists from './ReferenceLists';
import AgreementNavigation from './AgreementNavigation';
import {cleanUserName, b64Encode, b64Decode} from './Utilities';

import {
  OwcDoubleGlobalArea, OwcIcon, OwcIconButton, OwcTabs, OwcTab, OwcSlideToggle,
  OwcTypography, OwcBackdrop, OwcSplitScreen, OwcModalDialog, OwcButton
} from '@one/react';

import loginBackground from "./images/ContractLaw-wikipedia-public-domain.jpg"

import Amplify, { Auth } from "aws-amplify";


/**
 * The main entry point for the applicaiton
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */

Amplify.configure(configData.COGNITO_CONFIG)
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: configData.TAB_EXTRACT,
      showAbout: false,
      splitterSize: 35,
      customerAgreementId: null,
      updateAgreementList: null,
      unsavedChanges: false,
      pendingTabChange: null,
      pendingAgreementChange: null,
      userIsAuthenticated: false,
      userIsAuthorized: false,
      userName: null,
      groups: null,
      alternateGroups: null,
      waiting: true,
    }
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        const idToken = (await Auth.currentSession()).getIdToken();
        const username = cleanUserName(idToken.payload["cognito:username"]);
        const roles = idToken.payload["roche:roles"];
        const userIsAuthorized =  (roles.includes(configData.ROLE_STEWARD + configData.ENV_ROLE_SUFFIX) || 
                                    roles.includes(configData.ROLE_AFFILIATE + configData.ENV_ROLE_SUFFIX))  ? true : false;
        this.setState({ userName: username,
          userIsAuthenticated: true,
          userIsAuthorized: userIsAuthorized,
          waiting: false,
          groups: b64Encode(roles)});
      }
    } catch (e) {
      this.setState({
        userName: null,
        groups: null,
        waiting: false,
      });
      if (e !== 'No current user') {
        alert(e);
      }
    };
  }

  /**
   * Checks if a user has a particular role, this takes into account the _dev or _prod suffixes
   * @param {*} roleToCheck The string of the role to check wether against the users roles
   * @param {*} allowAlternate If true then the alternate groups location is also checked
   * @param {*} allowCurrent If true then the current groups location is checked (default true)
   * @returns true / false
   */
   userHasRole(roleToCheck, allowAlternate = false, allowCurrent = true) {
    if (allowCurrent) {
      const groups = this.state.groups;
      if (groups !== null) {
        return b64Decode(groups).includes(roleToCheck + configData.ENV_ROLE_SUFFIX);
      }
    }
    if (allowAlternate) {
      const alternateGroups = this.state.alternateGroups;
      if (alternateGroups !== null) {
        return b64Decode(alternateGroups).includes(roleToCheck + configData.ENV_ROLE_SUFFIX);
      }
    }
    return false;
  }

  /**
   * handles the row click event from the agreementNavigation control
   * @param {*} ev the event
   * @param {*} agreementId  the selected agreeemntId
   */
  handleAgreementSelection(ev, agreementId) {
    if (this.state.unsavedChanges === true) {
      if (this.state.pendingAgreementChange === null) {
        this.setState({ pendingAgreementChange: agreementId });
      }
    } else {
      this.setState({ customerAgreementId: agreementId });
    }
  }


  /**
   * handles the add new click event from the agreementNavigation control
   * @param {*} ev the event
   */
  handleAddNewClick(ev) {
    if (this.state.customerAgreementId !== null) {
      if (this.state.unsavedChanges === true) {
        if (this.state.pendingAgreementChange === null) {
          this.setState({ pendingAgreementChange: -1 });
        }
      } else {
        this.setState({
          selectedTab: configData.TAB_EXTRACT,
          customerAgreementId: null
        });
      }
    }
  }

  handleTabChange(tabName) {
    if (this.state.unsavedChanges === true) {
      if (this.state.pendingTabChange === null) {
        this.setState({ pendingTabChange: tabName });
      }
    } else {
      this.setState({ selectedTab: tabName });
    }
  }

  handleSizeChange(size) {
    this.setState({ splitterSize: size })
  }

  handleListChanged(agreementId) {
    this.setState({
      updateAgreementList: Date.now(),
      customerAgreementId: agreementId
    })
  }

  handleModalAbandonChanges() {
    if (this.state.pendingTabChange !== null) {
      const newTab = this.state.pendingTabChange;
      this.setState({
        unsavedChanges: false,
        pendingTabChange: null,
        pendingAgreementChange: null,
        selectedTab: newTab
      });
    } else {
      const newAgreementId = this.state.pendingAgreementChange === -1 ? null : this.state.pendingAgreementChange;
      this.setState({
        unsavedChanges: false,
        pendingTabChange: null,
        pendingAgreementChange: null,
        customerAgreementId: newAgreementId
      });
    }
  }

  handleRoleToggle() {
    const tabsThatWillDisappear = [configData.TAB_HARMONIZE, configData.TAB_REFERENCE_LISTS]
    const stateChange = {
      groups: this.state.alternateGroups,
      alternateGroups: this.state.groups
    };
    if (!this.userHasRole(configData.ROLE_STEWARD, true, false)) {
      if ((this.state.unsavedChanges === false) &&
          (tabsThatWillDisappear.includes(this.state.selectedTab))) {
        stateChange.selectedTab = configData.TAB_EXTRACT;
      }
    }
    this.setState(stateChange);
  }

  toggleAbout() {
    const isVisible = this.state.showAbout;
    this.setState({ showAbout: !isVisible })
  }

  renderLoginScreen() {
    return (
      <div name="loginScreen" style={{ 
        backgroundRepeat:"no-repeat",backgroundImage: `url(${loginBackground})`,
        backgroundSize:"cover" }}>
        <div style={{backgroundColor: "rgba(255, 255, 255, 0.7)",
          display:"flex", flexDirection:"column",
          alignItems:"center"}}>
            {this.state.waiting?
              <owc-progress-spinner style={{marginTop:"30vh", marginBottom:"40vh"}} />
            :
              <>
                <OwcTypography variant="title4" style={{paddingTop:"30vh"}}>Please Sign in</OwcTypography>
                <OwcButton style={{marginTop:"1em", marginBottom:"38vh", padding:"0.5em 1em"}}
                  onclick={() => Auth.federatedSignIn()}>
                    <OwcTypography variant="title6">Sign In</OwcTypography>
                </OwcButton>
                <span style={{background: "radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)",
                              display:"flex", flexDirection:"column",
                              alignItems:"center", width:"90%", maxWidth:"80em", position: "fixed",
                              bottom:"0%", border:"1px solid dimgrey", borderRadius:"10px"}}>
                  <p style={{margin:"0.8em", fontSize:"0.4em"}}>
                  <OwcTypography variant="caption" style={{color:"var(--one-color-active-primary-background)", fontWeight:"bold"}}>Confidentiality Disclaimer: </OwcTypography>
                  <OwcTypography variant="caption" style={{color:"dimgrey"}}>I acknowledge that I am bound by confidentiality obligations imposed through my employment 
                    or contractual agreement with Roche in connection with my access to confidential information, including this system and its contents.
                    By entering this system, I confirm that I understand that my activities within this system may be 
                    monitored consistent with local law, and all contents and passwords are confidential information, and that unauthorized disclosure 
                    or use of such confidential information may result in disciplinary action including termination of my employment or services 
                    and/or legal action based on local law.
                  </OwcTypography>
                  </p>
                </span>
              </>       
            }
          </div>
      </div>
    );
  }

  renderNotAuthorizedScreen() {
    return (
      <div name="notAuthorizedScreen" style={{ 
        backgroundRepeat:"no-repeat",backgroundImage: `url(${loginBackground})`,
        backgroundSize:"cover" }}>
        <div style={{backgroundColor: "rgba(255, 255, 255, 0.7)",
          display:"flex", flexDirection:"column",
          alignItems:"center"}}>
            {this.state.waiting?
              <owc-progress-spinner style={{marginTop:'30vh', marginBottom:"40vh"}} />
            :
              <>
                <OwcTypography variant="title4" style={{paddingTop:"30vh"}}>You are not authorized to use this application (please sign out)</OwcTypography>
                <OwcButton style={{marginTop:"1em", marginBottom:"38vh", padding:"0.5em 1em"}}
                  onClick={() => {
                    Auth.signOut();
                    this.setState({ userIsAuthenticated: false, waiting: true });
                  }}
                >
                <OwcTypography variant="title6">Sign Out</OwcTypography></OwcButton>
              </>       
            }
          </div>
      </div>
    );
  }
  

  renderLoginLogout() {
    if (this.state.userIsAuthenticated === true) {
      return (
        < >
          {this.userHasRole(configData.ROLE_STEWARD, true) ? (
            < >
              <OwcTypography style={{ marginRight: "1em" }} variant="badge">Current Role</OwcTypography>
              <OwcSlideToggle checked={this.userHasRole(configData.ROLE_STEWARD)}
                onInputChange={() => this.handleRoleToggle()}
              />
              <OwcTypography style={{ marginRight: "1em" }} variant="badge">
                {this.userHasRole(configData.ROLE_STEWARD) ? 
                  configData.ROLE_STEWARD 
                  : 
                  configData.ROLE_AFFILIATE
                }
              </OwcTypography>
            </>)
            : ""
          }
          <OwcTypography style={{ marginRight: "1em" }} variant="badge">{cleanUserName(this.state.userName)}

            {this.userHasRole(configData.ROLE_STEWARD, true) ? < ><br />({configData.ROLE_STEWARD})</> : ""}
          </OwcTypography>
          <OwcButton onClick={() => {
            Auth.signOut();
            this.setState({ userIsAuthenticated: false, waiting: true });
          }
          }>Sign Out</OwcButton>
        </>
      );
    } else {
      return;
    }
  }

  renderTabs() {
    if (this.state.userIsAuthenticated) {
      return (
        <OwcTabs value={this.state.selectedTab} onTabChange={(ev) => this.handleTabChange(ev.detail)}>
          <OwcTab value={configData.TAB_EXTRACT}><span><OwcTypography>{configData.TAB_EXTRACT}</OwcTypography></span></OwcTab>
          <OwcTab value={configData.TAB_TRANSLATE}><span><OwcTypography>{configData.TAB_TRANSLATE}</OwcTypography></span></OwcTab>
          {this.userHasRole(configData.ROLE_STEWARD) ?
            <OwcTab key={configData.TAB_HARMONIZE} value={configData.TAB_HARMONIZE}><span><OwcTypography>{configData.TAB_HARMONIZE}</OwcTypography></span></OwcTab>
            :
            null
          }
          <OwcTab value={configData.TAB_OVERVIEW}><span><OwcTypography>{configData.TAB_OVERVIEW}</OwcTypography></span></OwcTab>
          {this.userHasRole(configData.ROLE_STEWARD) ?
            <OwcTab key={configData.TAB_REFERENCE_LISTS} value={configData.TAB_REFERENCE_LISTS}><span><OwcTypography>{configData.TAB_REFERENCE_LISTS}</OwcTypography></span></OwcTab>
            :
            null
          }
        </OwcTabs>
      );
    }
  }

  renderBody() {
    if (this.state.selectedTab === configData.TAB_REFERENCE_LISTS) {
      return (this.renderTab(this.state.selectedTab, this.state.customerAgreementId));
    } else {
      return (
        <OwcSplitScreen style={{ height: "100vh" }} size={this.state.splitterSize} onSizeChange={(ev) => this.handleSizeChange(ev.detail)}>
          <div slot="primary">
            <AgreementNavigation updateFlag={this.state.updateAgreementList}
              onRowClick={(ev, agreeemntId) => this.handleAgreementSelection(ev, agreeemntId)}
              onAddNewClick={(ev) => this.handleAddNewClick(ev)}
              currentAgreement={this.state.customerAgreementId}
              splitterSize={this.state.splitterSize}
            />
          </div>
          <div slot="secondary">
            <div name="tabContents" className="tabContents">
              {this.renderTab(this.state.selectedTab, this.state.customerAgreementId)}
            </div>
          </div>
        </OwcSplitScreen>
      );
    }
  }

  renderTab(selectedTab, customerAgreementId) {
    if (selectedTab === configData.TAB_EXTRACT) {
      return (
        <CaptureAgreement customerAgreementId={customerAgreementId}
          onAgreementListChanged={(id) => this.handleListChanged(id)}
          onUnsavedChangesChange={(isUnsaved) => this.setState({ unsavedChanges: isUnsaved })}
          userName={this.state.userName}
          dataSteward={this.userHasRole(configData.ROLE_STEWARD)} />
      );
    }
    if (selectedTab === configData.TAB_TRANSLATE) {
      return (
        <TranslateAndStructure customerAgreementId={customerAgreementId}
          userName={this.state.userName}
          onUnsavedChangesChange={(isUnsaved) => this.setState({ unsavedChanges: isUnsaved })} />
      );
    }
    if (selectedTab === configData.TAB_HARMONIZE) {
      return (
        <Harmonize customerAgreementId={customerAgreementId}
          userName={this.state.userName}
          onUnsavedChangesChange={(isUnsaved) => this.setState({ unsavedChanges: isUnsaved })}
        />
      );
    }
    if (selectedTab === configData.TAB_REFERENCE_LISTS) {
      return (
        <ReferenceLists
          userName={this.state.userName}
          onUnsavedChangesChange={(isUnsaved) => this.setState({ unsavedChanges: isUnsaved })}
        />
      );
    }
    if (selectedTab === configData.TAB_OVERVIEW) {
      return (
        <ReviewAndPublish customerAgreementId={customerAgreementId}
          userName={this.state.userName}
          dataSteward={this.userHasRole(configData.ROLE_STEWARD)} />
      );
    }
  }

  renderWarningDialog() {
    return (
      <OwcModalDialog disableBackdropClick disableEscapeKeydown disableScrollLock="false" hideBackdrop="false" size="sm"
        visible={(
          this.state.unsavedChanges &&
          (
            (this.state.pendingTabChange !== null) ||
            (this.state.pendingAgreementChange !== null)
          )
        )}>
        You have unsaved changes, are you sure you want to move without saving?
        <div slot="header">Unsaved Changes</div>
        <div slot="actions">
          <table width="100%">
            <tbody><tr>
              <td align="left">
                <OwcButton style={{ width: "fit-content" }} onclick={() => this.setState({ pendingTabChange: null, pendingAgreementChange: null })}                    >
                  No, stay on this page
                </OwcButton>
              </td>
              <td align="right">
                <OwcButton style={{ width: "fit-content" }} onclick={() => this.handleModalAbandonChanges()}>
                  Yes, abandon unsaved changes
                </OwcButton>
              </td>
            </tr></tbody>
          </table>
        </div>
      </OwcModalDialog>
    );
  }


  renderAbout() {
    return (
      <OwcBackdrop height="60%" width="60%"
        style={{ position: 'absolute', display: "bock" }}
        onBackdropClick={() => this.toggleAbout()}>
        <div className="about-screen-content-wrapper" style={{
          backgroundColor: 'var(--one-color-default-background)',
          height: 'calc(100% - 80px)',
          display: 'flex',
          flexDirection: 'column',
          padding: '80px 40px',
          fontFamily: 'var(--one-text-font-family-default)'
        }}>
          <div className="title-container" style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'var(--one-color-active-primary-background)'
          }}>
            <span className="title" style={{
              fontWeight: 'bold',
              fontSize: '33px',
              lineHeight: '49px'
            }}>
              Data Entitlements
            </span>
            <OwcIcon name="roche_logo" style={{ width: '77px', fontSize: '48px' }} />
          </div>
          <span className="subtitle" style={{
            fontStyle: 'italic',
            fontSize: '32px',
            lineHeight: '1',
            color: 'var(--one-color-active-primary-background)',
            margin: '8px 0'
          }}>
            About
          </span>
          <div className="content" style={{ flexGrow: 1, overflow: 'auto', margin: '48px 0 38px 0' }}>
            The goal of this application is to facilitate affiliate representatives the manual extraction and capture of data entitlements from existing agreements with customers. 
            <br/><br/>
            Through this user interface data is onboarded in the <a href="https://roche.collibra.com/community/e3ad7c1d-9b9a-4e39-a821-bc2fb8d228cf">Data Entitlements domain</a>, where affiliates, together with data stewards, capture, translate, structure and interpret the information about data processing and data use from the clauses mentioned in the existing agreements with customers. 
            <br/><br/>
            In order to harmonize agreement information across customer parties, data clauses are translated by experts and organized into a structured form, so that they are both universally understandable and close to the wording in the original agreement.
          </div>
        </div>
      </OwcBackdrop>
    );
  }

  renderEnvironment() {
    const productionBranch = "production";
    if (!(configData.BRANCH_NAME === productionBranch ||
          configData.BRANCH_NAME.endsWith(" " + productionBranch)))
    {
      return (
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <span style={{marginRight:"0.5em"}}>
            <OwcTypography variant="badge">Environment:</OwcTypography>
          </span>
          <span><OwcTypography variant="badge">{configData.BRANCH_NAME}<br/>{configData.DEV_OR_PROD}</OwcTypography>
          </span>
        </div>
      );
    }
  }

  render() {
    return (
      <div name="app">
        <OwcDoubleGlobalArea>
          <div slot="top">
            <div style={{ display: 'flex' }}>
              <OwcTypography variant="title5">Data Entitlements</OwcTypography>
            </div>
            {this.renderEnvironment()}
          </div>
          <div slot="primary">
            <div style={{ display: 'flex' }}>
              <OwcIconButton id="menu_anchor" flat icon="menu" onClick={(ev) => this.toggleAbout(ev.detail)} />
              <OwcTypography style={{ marginLeft: '1em', marginTop: '0.6em', textAlign: 'left' }}>Agreements</OwcTypography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {this.renderLoginLogout()}
              <OwcIcon style={{ width: '48px', marginLeft: '8px', color: 'var(--one-color-active-primary-background)', float: 'right' }} name="roche_logo" />

            </div>
          </div>
          <div slot="secondary">
            {this.renderTabs()}
          </div>
        </OwcDoubleGlobalArea>
        {this.state.showAbout ? this.renderAbout() : ""}
        {this.renderWarningDialog()}

        
        {(this.state.userIsAuthenticated===true && this.state.userIsAuthorized ===true) ? 
          ( 
            this.renderBody()
          )
          :
          (this.state.userIsAuthenticated===true && this.state.userIsAuthorized !==true) ? 
            this.renderNotAuthorizedScreen() 
            : 
            this.renderLoginScreen()
        }
      </div>
    );
  }
}

export default App;
