/** Config file to hold API endpoints and shared constants across more than one file.
 * 
 * @copyright Roche 2022
 * @author Nick Draper
 */

/** Debug setting for the location of the API root
 * This is not used when built by Gitlab
 *
 * The location for the AWS hosted API for this branch can be extracted from the Gitlab CI/CD Pipeline for this branch
 * https://code.roche.com/dsfairdata/takeoff/-/pipelines
 * Click on the "deploy api's and lambdas" stage link for the most recent job from your branch
 * Towards the bottom of the log there is:
 *      Description         API Stage Endpoint
 *      Value               "https://vtslbhwrdk.execute-api.eu-central-1.amazonaws.com/api"         <- this is the URL you want
 */
 const LOCAL_API_URL = "https://vtslbhwrdk.execute-api.eu-central-1.amazonaws.com/api";
 const LOCAL_HOST_REDIRECT = "http://localhost:3000";
 //const LOCAL_API_URL = "http://localhost:3000"; 
 
 const configData = {
     REFDATA_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/ref_data/",
     CONTRACTS_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/contracts/",
     TERMPAIRS_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/termpairs/",
     ENTITLEMENTS_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/entitlements/",
     PROPAGATE_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/propagate/",
     PROPAGATED_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/propagated/",
     DEV_OR_PROD: ( process.env.REACT_APP_DEV_OR_PROD ? process.env.REACT_APP_DEV_OR_PROD : "local development" ),
     BRANCH_NAME: ( process.env.REACT_APP_BRANCH ? process.env.REACT_APP_BRANCH : "unknown branch" ),
     // These string MUST match those returned by the authorization 
     ENV_ROLE_SUFFIX : ( process.env.REACT_APP_DEV_OR_PROD === "production" ? "_prod" : "_dev" ),
     ROLE_STEWARD : "steward",
     ROLE_AFFILIATE : "affiliate",
     ROLE_CONSUMER : "consumer",
 
     ENTITLEMENT_DATA_USE: "Data Use",
     ENTITLEMENT_PROCESSING_ACTION: "Processing Action",
     REFDATA_DEPRECATED: " (Deprecated)",
 
     TAB_EXTRACT: "Capture Agreement",
     TAB_TRANSLATE: "Translate & Structure",
     TAB_HARMONIZE: "Harmonize",
     TAB_REFERENCE_LISTS: "Reference Lists",
     TAB_OVERVIEW: "Review & Publish",
 
     COGNITO_CONFIG: {
         Auth: {
             region: "eu-central-1",
             userPoolId: "eu-central-1_QzBB4tiCu",
             identityPoolId: (process.env.REACT_APP_ID_POOL_ID ? process.env.REACT_APP_ID_POOL_ID : "eu-central-1:25456bb5-fd97-4c17-9fd2-d2a0b506308a"), 
             identityPoolRegion: "eu-central-1",
             userPoolWebClientId: (process.env.REACT_APP_WEBCLIENT_ID ? process.env.REACT_APP_WEBCLIENT_ID :"74n5ql0neanit2th007du1dabl"),
             oauth: {
               domain: "dataentitlements.auth.eu-central-1.amazoncognito.com",
               scope: ["phone", "email", "openid"],
               redirectSignIn: (process.env.REACT_APP_DSFAIR_WEBSITE_URL ? process.env.REACT_APP_DSFAIR_WEBSITE_URL : LOCAL_HOST_REDIRECT),
               redirectSignOut: (process.env.REACT_APP_DSFAIR_WEBSITE_URL ? process.env.REACT_APP_DSFAIR_WEBSITE_URL : LOCAL_HOST_REDIRECT),
               responseType: "code"
             }
           }
     },
 
     AWS_REGION: "eu-central-1",
     AWS_SERVICE: "execute-api"
 }
 
 export {configData}
 
