import React from 'react';
import {configData} from "./config.js";
import './styles.scss';

import {formatDate, fetchSigned} from './Utilities.js'
import {
  OwcTable, OwcTableHeader, OwcTableHeaderCell,
  OwcTableBody, OwcTableRow, OwcTableCell,
  OwcButton, OwcTypography, OwcPagination,
  OwcInput, OwcIconButton
} from '@one/react';

/**
 * The a navigation control for selecting agreements
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class AgreementNavigation extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      affiliateList: [],
      searchBy: "Title",
      searchTerm: "",
      sortBy: "Most Recent",
      affiliateFilter: "All",
      sortList: ["Most Recent",
        "Title",
        "Customer name",
        "Customer account number",
        "Customer country",
        "Customer address",
        "Roche name",
        "Roche country",
        "Roche address",],
      searchList: ["Title",
        "Customer name",
        "Customer account number",
        "Customer country",
        "Customer address",
        "Roche name",
        "Roche country",
        "Roche address",],
      page: 1,
      rowsPerPage: 10,
      totalPages: 1,
      selectedAgreementId: null,
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    // we have been signalled to update
    if (this.props.updateFlag !== prevProps.updateFlag) {
      this.loadSearchResults();
    }
    if (this.props.currentAgreement !== this.state.selectedAgreementId) {
      this.setState({selectedAgreementId: this.props.currentAgreement});
    } 
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=false")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredAffiliateList = result.filter(value =>
            value.type === "Affiliate"
          );
          this.setState({ affiliateList: filteredAffiliateList });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )


    this.loadSearchResults();
  }

  loadSearchResults() {
    this.setState({
      isLoaded: false,
      error: null
    });
    //gather searchData
    const searchData = {
      searchBy: this.state.searchBy,
      searchTerm: this.state.searchTerm,
      sortBy: this.state.sortBy,
      affiliateFilter: this.getIdForRefData(this.state.affiliateFilter, this.state.affiliateList),
      limit: this.state.rowsPerPage,
      offset: (this.state.page - 1) * this.state.rowsPerPage,
    };

    fetchSigned(configData.CONTRACTS_API_URL + "search/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          const maxPage = Math.ceil(result.totalResultCount / this.state.rowsPerPage);
          this.setState({
            isLoaded: true,
            totalPages: maxPage,
            items: result.searchResults,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getIdForRefData(value, refdataList) {
    let retVal = null;
    const refDataItem = refdataList.find(({ description }) => description === value);
    //get the record
    if (refDataItem !== undefined) {
      retVal = refDataItem.refListId;
    }
    return retVal;
  }

  handlePageChange(ev) {
    const stateChanges = {};
    if (ev.rowsPerPage !== this.state.rowsPerPage) {
      stateChanges.rowsPerPage = ev.rowsPerPage;
    }
    if (ev.page !== this.state.page) {
      stateChanges.page = ev.page;
    }
    //if there are any state changes
    if (stateChanges)
    {
      this.setState(stateChanges, () => this.loadSearchResults());
    }
  }

  handleAffiliateFilterChange(ev) {
    if (ev.target.value !== this.state.affiliateFilter) {
      this.setState({ affiliateFilter: ev.target.value }, () => this.loadSearchResults());
    }
  }

  handleSortChange(ev) {
    if (ev.target.value !== this.state.sortBy) {
      this.setState(
        {
          sortBy: ev.target.value,
          page: 1,
        },
        () => this.loadSearchResults());
    }
  }

  handleSearchClick(ev) {
    this.loadSearchResults();
  }

  handleSearchKeyUp(ev) {
    // Number 13 is the "Enter" key on the keyboard
    this.setState({ searchTerm: ev.target.value });
    if (ev.keyCode === 13) {
      // Cancel the default action, if needed
      ev.preventDefault();
      // Start the search
      this.loadSearchResults();
    }
  }

  handleRowClick(ev, customerAgreementId){
    this.setState({selectedAgreementId: customerAgreementId}, 
      this.props.onRowClick(ev, customerAgreementId));
  }

  /**
   * Renders the agreements table control
   * @returns The JSX of the controls
   */
  renderTable(smallerSpace) {
    const error = this.state.error;
    const isLoaded = this.state.isLoaded;
    const items = this.state.items;

    //decide based on the space for this control wether to use two or three columns
    let noCols = 3;
    if (smallerSpace === true) {
      noCols = 2;
    }


    if (error) {
      return <div style={{ paddingTop: "1em", paddingBottom: "23em" }}>Error: {error.message}</div>;
    } else {
      return (
        <OwcTable key={"AgreementNavigationTable" + this.props.splitterSize} loading={!isLoaded} style={{ display: "block"}} spacing='dense' height="auto">
          <OwcTableHeader elevated sticky shrink>
            {noCols===3?
              <>
                <OwcTableHeaderCell key="titleHeader" resizable width="30%">Title</OwcTableHeaderCell>
                <OwcTableHeaderCell key="titleCustName" resizable width="30%">Customer name</OwcTableHeaderCell>
                <OwcTableHeaderCell key="titleRocheName" resizable width="28%">Roche name</OwcTableHeaderCell>
              </>
            :
              <>
                <OwcTableHeaderCell key="titleHeader" resizable width="40%">Title</OwcTableHeaderCell>
                <OwcTableHeaderCell key="titleCustName" resizable width="40%">Customer name</OwcTableHeaderCell>
              </>
            }
          </OwcTableHeader>
          <OwcTableBody>
            {items.map(item => (
              <OwcTableRow key={"row" + item.customerAgreementId} expandable onClick={(ev) => this.handleRowClick(ev, item.customerAgreementId)}>
                <OwcTableCell style={this.state.selectedAgreementId === item.customerAgreementId? {backgroundColor: '#E0ECF9'}: {}} 
                  key={"titleCell" + item.customerAgreementId}>{item.title}</OwcTableCell>
                <OwcTableCell style={this.state.selectedAgreementId === item.customerAgreementId? {backgroundColor: '#E0ECF9'}: {}} 
                key={"customerNameCell" + item.customerAgreementId}>{item.customerName}</OwcTableCell>
                {noCols===3?
                  <OwcTableCell style={this.state.selectedAgreementId === item.customerAgreementId? {backgroundColor: '#E0ECF9'}: {}} 
                  key={"rocheNameCell" + item.customerAgreementId}>{item.rocheName}</OwcTableCell>
                 :
                  <></>
                 }
                <div key={"expandedRow" + item.customerAgreementId} slot="expanded">
                  Customer: {item.customerName}, {item.customerAddress}, {item.customerCountry}<br />
                  {item.customerAccountNo===null?"":<>Customer account number: {item.customerAccountNo}<br /></>}
                  Roche: {item.rocheName}, {item.rocheAddress}, {item.rocheCountry} <br/>
                  Valid from: {item.validFrom === null ? "undefined date" : formatDate(item.validFrom)}, 
                  To: {item.terminationDate === null ? "undefined date" : formatDate(item.terminationDate)} <br />
                </div>
              </OwcTableRow>
            ))}
          </OwcTableBody>
        </OwcTable>
      );
    }
  }

  /**
 * Renders the affiliate filter control
 * @returns The JSX of the controls
 */
  renderSearchAndSort() {
    return (
      <table style={{ marginTop: "4%" }} width="100%">
        <tbody>
          <tr>
            <td width="20%"> <OwcTypography>Filter by Affiliate:</OwcTypography> </td>
            <td>
              <select style={{ width: "100%"}}
                value={this.state.affiliateFilter}
                onChange={(ev) => this.handleAffiliateFilterChange(ev)}
              >
                <option>All</option>
                {this.state.affiliateList.map(item => (
                  <option key={"NavAffiliate" + item.refListId}>{item.description}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td> <OwcTypography >Search by:</OwcTypography> </td>
            <td>
              <select style={{ width: "100%" }}
                value={this.state.searchBy}
                onChange={(ev) => { this.setState({ searchBy: ev.target.value }) }}
              >
                {this.state.searchList.map(item => (
                  <option key={"NavSearch" + item}>{item}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <OwcInput
                style={{ width: "100%" }}
                compact={true}
                placeholder="Press Enter to Search..."
                round={false}
                variant="filled"
                value={this.state.searchTerm}
                onKeyUp={(ev) => this.handleSearchKeyUp(ev)}
              >
                <OwcIconButton slot="prefix" icon="search" flat onClick={(ev) => this.handleSearchClick(ev)} />
              </OwcInput>
            </td>
          </tr>
          <tr>
            <td> <OwcTypography >Sort by:</OwcTypography> </td>
            <td>
              <select style={{ width: "100%" }}
                value={this.state.sortBy}
                onChange={(ev) => this.handleSortChange(ev)}
              >
                {this.state.sortList.map(item => (
                  <option key={"NavSort" + item}>{item}</option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    //decide based on the space for this control needs to be squeezed
    let smallerSpace = false;
    let fontSize = 0.75;
    let margins = {};
    if (((window.innerWidth/100) * this.props.splitterSize) < 400) {
      smallerSpace = true;
      margins = {marginRight:"-0.4em", marginLeft:"-1.5em"};
      fontSize = fontSize * (((window.innerWidth/100) * this.props.splitterSize)/400)
      if (fontSize < 0.6) {
        margins = {marginRight:"-0.5em", marginLeft:"-2em"};
        fontSize = 0.6;
      }
    }

    return (
      <div className="AgreementNavigation;">
        <OwcButton style={{ marginTop: "4%" }}
          onClick={(ev) => this.setState({selectedAgreementId: null}, this.props.onAddNewClick(ev))}
        >
          Add New Agreement
        </OwcButton>
        <hr></hr>
        <OwcTypography style={{ marginTop: "4%", fontWeight: "bold" }} >Or select an agreement:</OwcTypography>
        {this.renderSearchAndSort()}
        <br />
        {this.renderTable(smallerSpace)}
        <OwcPagination page={this.state.page} rowsPerPage={this.state.rowsPerPage}
          showPerPage={true} total={this.state.totalPages} options={[10,20,50]}
          onPageChange={(ev) => this.handlePageChange(ev.detail)}
        >
          <div slot="rows-per-page"><OwcTypography style={{fontSize:fontSize + "em"}}> Rows per Page</OwcTypography> </div>
          <div style={margins}><OwcTypography style={{fontSize:fontSize + "em"}}> {this.state.page} of {this.state.totalPages} </OwcTypography></div>
        </OwcPagination>
      </div>
    );
  }
}

export default AgreementNavigation;
