import React from 'react';
import {configData} from "./config.js";
import './styles.scss';
import {formatDate, convertToDate, fetchSigned} from "./Utilities.js"
import DataClauses from "./components/DataClauses.js";
import {
  OwcTypography, OwcTable, OwcTableBody,
  OwcTableRow, OwcTableCell, OwcTableHeaderCell,
  OwcTableHeader, OwcButton, OwcCheckbox
} from '@one/react';

//import { configure } from '@testing-library/react';

/**
 * The interactive form for viewing the traceability report for "edit mode" or specific versions
 * Also allows the "publishing of "Edit Mode" to a specific version
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class ReviewAndPublish extends React.Component {
  RIGHT_ARROW = "→";
  ERROR = "error";
  WARNING = "warning";
  EDITABLE_VERSION_TEXT = "Current draft version";
  VERSION_PREFIX_TEXT = "Version ";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedAgreementLoading: false,
      selectedAgreementId: this.props.customerAgreementId,
      agreement: {},
      pairs: [],
      termPairEntitlementMapping: {},
      refListsComplete: [],
      entitlements: [],
      versions: [],
      selectedVersion: 0,
      entitlementsLoaded: false,
      pairsLoaded: false,
      refdataLoaded: false,
      submissionState: null,
      errors: [],
      warnings: [],
      agreementControlValidations: {},
      maxVersion: 0,
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
      this.updateForm(this.props.customerAgreementId, 0, true);
      //this has changed the agreement displayed, clear the submissionState
      this.setState({ submissionState: null });
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ refListsComplete: result,
                          refdataLoaded: true },
                        () => this.checkValidation());;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    this.updateForm(this.props.customerAgreementId, 0, true);

  }

  /** Updates all of the loaded data for the form
   * @param {number} agreementId the selected agreement id
   */
  updateForm(agreementId, versionNo, overwriteSubmissionState = true) {
    if (agreementId == null) { return };

    // wipe the last agreement
    const resetState = {
      selectedAgreementId: agreementId,
      selectedVersion: versionNo,
      selectedAgreementLoading: true,
      errors: [],
      warnings: [],
      agreementControlValidations: {},
      versions: [],
      entitlementsLoaded: false,
      pairsLoaded: false,
    };
    if (overwriteSubmissionState === true) {
      resetState.submissionState = null;
    };
    this.setState(resetState,() => {
        // load the propagated version list
        this.loadVersions(agreementId);      
        // load the pairs
        this.loadTermPairs(agreementId, versionNo);
        // load the new agreement data
        this.loadAgreementData(agreementId, versionNo);
        // load the entitlements
        this.loadEntitlementData(agreementId, versionNo);
      }
    );
  }

  /**
   * Loads the list of versions of this agreement
   * @param {*} agreementId the selected agreement id
   */
  loadVersions(agreementId){
    //get the agreement versions
    fetchSigned(configData.PROPAGATED_API_URL + `contracts/${agreementId}/`)
    .then(res => res.json())
    .then(
      (result) => {
        const versionNoList = result.map(({versionNo}) => versionNo);
        const maxVersion = versionNoList.length > 0? Math.max(...versionNoList)  : 0;
        this.setState({ versions: result, maxVersion: maxVersion});
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  /**
   * Loads the agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   * @param {*} versionNo the selected version no
   */
  loadAgreementData(agreementId, versionNo) {
    //get the agreement details
    let url = configData.CONTRACTS_API_URL + agreementId + "/";
    if (versionNo > 0) {
      url = `${configData.PROPAGATED_API_URL}contracts/?` + 
        `customerAgreementId=${agreementId}&versionNo=${versionNo}`;
    }
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          const row = result[0];
          this.setState({ agreement: row,
            selectedAgreementLoading:false }, 
            () => this.checkValidation());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Loads the entitlements and stores them in the state
   * @param {*} agreementId the selected agreement id
   * @param {*} versionNo the selected version no
   */
  loadEntitlementData(agreementId, versionNo) {
    let url = configData.ENTITLEMENTS_API_URL + agreementId + "/"
    if (versionNo > 0) {
      url = `${configData.PROPAGATED_API_URL}entitlements/?` + 
        `customerAgreementId=${agreementId}&versionNo=${versionNo}`;
    }
    console.log(url)
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          const termPairEntitlementMapping = {};
          result.forEach(entitlement => {
            if (entitlement.entitlementType === null) {
              // convert the dates
              entitlement.validFrom = convertToDate(entitlement.validFrom);
              entitlement.terminationDate = convertToDate(entitlement.terminationDate);

              // look up the type from the first mapped pair
              if (entitlement.mappedPairs.length > 0) {
                const mappedPairId = entitlement.mappedPairs[0];
                const mappedPair = this.state.pairs.find(({ termPairingNo }) => termPairingNo === mappedPairId);
                if (mappedPair !== undefined) {
                  if (mappedPair.pairingType === configData.ENTITLEMENT_PROCESSING_ACTION) {
                    entitlement.entitlementType = configData.ENTITLEMENT_PROCESSING_ACTION;
                  } else {
                    entitlement.entitlementType = configData.ENTITLEMENT_DATA_USE;
                  }
                }
              }
            }
            entitlement.mappedPairs.forEach((mappedPairId) => {
              if (termPairEntitlementMapping[mappedPairId]) {
                if (!termPairEntitlementMapping[mappedPairId].includes(entitlement.entitlementId)) {
                  termPairEntitlementMapping[mappedPairId].push(entitlement.entitlementId);
                }
              } else {
                termPairEntitlementMapping[mappedPairId] = [entitlement.entitlementId];
              }
            });
          })

          this.setState({
              entitlements: result,
              termPairEntitlementMapping: termPairEntitlementMapping,
              entitlementsLoaded: true,
            },
            () =>{this.checkValidation()} 
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /** Loads term pairs from the API
   * @param {number} agreementId The selected agreement ID
   * @param {number} versionNo The selected version No
   */
  loadTermPairs(agreementId, versionNo) {
    let url = configData.TERMPAIRS_API_URL + agreementId + "/"
    if (versionNo > 0) {
      url = `${configData.PROPAGATED_API_URL}termpairs/?` + 
        `customerAgreementId=${agreementId}&versionNo=${versionNo}`;
    }
    this.setState({ pairsLoaded: false });
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            pairs: result,
            pairsLoaded: true
          },
          () => this.checkValidation());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error,
            pairsLoaded: true
          });
        }
      )
  }

  /**
   * looks up a ref data item and returns the string with an optional deprecation warning
   * @param {*} refListIdToLookup The id to look up
   * @param {*} alternateValue if not null this will be used for the ref data value
   * @param {*} includeDeprecationWarning if true and the ref list item is not active add a warning
   * @returns the strinbg desctrption of the ref data item, or null if it cannot be found
   */
  lookupRefData(refListIdToLookup, alternateValue = null, includeDeprecationWarning = true) {
    let retVal = alternateValue;
    const refDataEntry = this.state.refListsComplete.find(({ refListId }) => refListId === refListIdToLookup);
    if (refDataEntry !== undefined) {
      if (retVal === null ) {
        retVal = refDataEntry.description;
      }
      if ((includeDeprecationWarning) && (!refDataEntry.isActive)) {
        retVal += configData.REFDATA_DEPRECATED;
      }
    }
    return (retVal);
  }

  /** Check all the validation rules against the loaded data 
   * and enters warnings and errors into the state 
   * Ensuring that the validations codes run sequentially and only once all of the data is available,
   * otherwise the warnings can get overwritten. */
  checkValidation(){
    if (this.state.selectedAgreementLoading === false &&
      this.state.pairsLoaded === true &&
      this.state.entitlementsLoaded === true &&
      this.state.refdataLoaded === true)
      {
        const controlValidations = {};
        const warnings = [];
        const errors = [];
        this.checkAgreementValidation(warnings, errors, controlValidations);
        this.checkPairsValidation(warnings);
        this.checkEntitlementValidation(warnings, errors);
        
        this.setState({errors: errors, 
          warnings: warnings, 
          agreementControlValidations: controlValidations});
      }
  }

  /** Check validation rules against the loaded agreement data 
   * and enters warnings and errors into the state 
   * @param {*} warnings a list of warnings 
   * @param {*} errors a list of errors 
   * @param {*} controlValidations a dictionary of controls with the valiadation type (warning/error) */
  checkAgreementValidation(warnings, errors, controlValidations) {
    if (this.state.selectedVersion !== 0)
    {
      // only check validation for the ediateble version
      return;
    }
    const agreement = this.state.agreement;

    // warnings
    const agreementNotNullWarningsFields = [
      {field:"customerAddress", text:"Customer Address"},
      {field:"customerCountry", text:"Customer Country"},
      {field:"rocheAddress", text:"Roche Address"},
      {field:"rocheCountry", text:"Roche Country"}
    ];
    agreementNotNullWarningsFields.forEach(warningCheck => {
      if (agreement[warningCheck.field] === null || 
        agreement[warningCheck.field].trim() === "")
      {
          warnings.push({tab:configData.TAB_EXTRACT,
                         warning:`The ${warningCheck.text} should be set for the agreement`});
          controlValidations[warningCheck.field] = this.WARNING;
      }
    });
    const affiliateLabel = this.lookupRefData(agreement.rocheAffiliateId);
    if (affiliateLabel && affiliateLabel.endsWith(configData.REFDATA_DEPRECATED)) {
        warnings.push({tab:configData.TAB_EXTRACT,
                      warning:"The Roche Affiliate value is Deprecated"});
        controlValidations.rocheAffiliateId = this.WARNING;
    }

    // errors
    if (agreement.validFrom === null)
    {
        errors.push({tab:configData.TAB_EXTRACT,
                    error:"Valid From must be set for the agreement"});
        controlValidations.validFrom = this.ERROR;
    }
    const dateErrorText = this.checkValidityDates(agreement.validFrom, agreement.terminationDate);
    if (dateErrorText !== null){
      errors.push({tab:configData.TAB_EXTRACT,
                  error:dateErrorText});
      controlValidations.terminationDate = this.ERROR;
    }
    if (agreement.rocheAffiliateId === null)
    {
        errors.push({tab:configData.TAB_EXTRACT,
                    error:"The Roche Affiliate must be set for the agreement"});
        controlValidations.rocheAffiliateId = this.ERROR;
    }
  }

  /**
   * checks the date validation across the validFrom and terminationDate fields
   * @returns null if thre is no error, otherwise a string value of the error message
   */
   checkValidityDates(validFrom, termDate) {
    if ((termDate !== null) && (termDate.length !== 0)) {
      if ((validFrom !== null) && (validFrom.length !== 0)) {
        if (convertToDate(termDate) < convertToDate(validFrom)) {
          return "The Valid From date must be less than the Termination Date";
        }
      }
    }
    return null;
  }

  /** Check validation rules against the loaded entitlement data 
   * and enters warnings into the state 
   * @param {*} warnings a list of warnings 
   * @param {*} errors a list of errors */
  checkEntitlementValidation(warnings, errors) {
    if (this.state.selectedVersion !== 0)
    {
      // do not check validation for propagated versions
      return;
    }
    const entitlements = this.state.entitlements;
    if (entitlements.length === 0)
    {
      // do not check validation if there are no entitlements
      return;
    }

    entitlements.forEach(entitlement => {
      if (entitlement.mappedPairs.length===0)
      {
          warnings.push({tab:configData.TAB_HARMONIZE,
                      warning:`Entitlement ${this.lookupRefData(entitlement.dataCategoryCode)} ` +
                              `${this.RIGHT_ARROW} ${this.lookupRefData(entitlement.dataUsageCode)} has no mapping`});
      }

      const dateErrorText = this.checkValidityDates(entitlement.validFrom, entitlement.terminationDate);
      if (dateErrorText !== null){
          errors.push({tab:configData.TAB_HARMONIZE,
                      error:`Entitlement ${this.lookupRefData(entitlement.dataCategoryCode)} ` +
                      `${this.RIGHT_ARROW} ${this.lookupRefData(entitlement.dataUsageCode)} ` +
                      `${dateErrorText}`});
      }

      if (this.isRefDataDeprecated(entitlement.dataCategoryCode) || this.isRefDataDeprecated(entitlement.dataUsageCode))
      {
          warnings.push({tab:configData.TAB_HARMONIZE,
                      warning:`Entitlement ${this.lookupRefData(entitlement.dataCategoryCode)} ` +
                              `${this.RIGHT_ARROW} ${this.lookupRefData(entitlement.dataUsageCode)} has a deprecated value`});
      }
    });
  }

  /** Check validation rules against the loaded term pair data 
   * and enters warnings into the state 
   * @param {*} warnings a list of warnings */
  checkPairsValidation(warnings) {
    if (this.state.selectedVersion !== 0)
    {
      // do not check validation for propagated versions
      return;
    }
    const pairs = this.state.pairs;
    if (pairs.length === 0 || this.state.entitlementsLoaded === false)
    {
      // do not check validation if there are no pairs or the entitlements have not loaded yet
      return;
    }

    pairs.forEach(pair => {
      if (pair.dataCategoryText === null || pair.dataCategoryText.trim() === "")
      {
          warnings.push({tab:configData.TAB_TRANSLATE,
                      warning:`${pair.pairingType} "" ${this.RIGHT_ARROW} ${pair.dataUsageText} has no value for Data Category`});
      }
      if (pair.dataUsageText === null || pair.dataUsageText.trim() === "")
      {
          warnings.push({tab:configData.TAB_TRANSLATE,
                      warning:`${pair.pairingType} ${pair.dataCategoryText} ${this.RIGHT_ARROW} "" has no value for Data Usage`});
      }
      if (pair.termPairingNo in this.state.termPairEntitlementMapping && pair.notMapped) {
        warnings.push({tab:configData.TAB_HARMONIZE,
          warning:`${pair.pairingType} ${pair.dataCategoryText} ${this.RIGHT_ARROW} ${pair.dataUsageText}` +
                  " is marked Cannot Map, but is also mapped to " +
                  (this.state.termPairEntitlementMapping[pair.termPairingNo].length === 1? "an ": "") +
                  "entitlement" +
                  (this.state.termPairEntitlementMapping[pair.termPairingNo].length === 1? "": "s")
        });
      }
      if ((!(pair.termPairingNo in this.state.termPairEntitlementMapping)) && (!pair.notMapped)) {
        warnings.push({tab:configData.TAB_HARMONIZE,
          warning:`${pair.pairingType} ${pair.dataCategoryText} ${this.RIGHT_ARROW} ${pair.dataUsageText}` +
                  " is not mapped yet, please mark as \"cannot map\" if no mapping to an entitlement is possible"
        });
      }
    });

  }

  /**
   * Returens a style for this element marking the color based on the validation status
   * @param {*} fieldName The name of the control to be check for validation status
   * @param {*} existingStyles A distionary of additional style elements to include
   * @returns a style dictionary with the validation style included
   */
  setValueColor(fieldName, existingStyles =  {}) {
    const controlValidations = this.state.agreementControlValidations;
    if (fieldName in controlValidations) {
      existingStyles.color=controlValidations[fieldName] === this.ERROR?
                            "red" : "orange";
    }

    return existingStyles;
  }

  /** handles the click event of the Publish button
   * 
   * @param ev The click event
   */
  handleSubmitClick(ev){
    // check the form is valid
    const submissionData = {
      customerAgreementId: this.state.selectedAgreementId,
      publishedBy: this.props.userName,
    };

    this.setState({ submissionState: "Publishing ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      return fetchSigned(configData.PROPAGATE_API_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 201) // inserted successfully
          {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              const newVersionNo = parseInt(json.versionNo);
              this.setState({ submissionState: `Version ${newVersionNo} successfully published`,
                              selectedVersion: newVersionNo})
              this.updateForm(this.state.selectedAgreementId, newVersionNo, false);
            }).catch((error) => {
              this.setState({ submissionState: "Error publishing version " + error });
              console.error(error);
            });
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              this.setState({ submissionState: "Error publishing version " + json.errorText });
            }).catch((error) => {
              this.setState({ submissionState: "Error publishing version " + error })
              console.error(error);
            });
          }
        });
    };
    submitForm();
  }

/**
 * handles the change of the version selection box
 * @param {*} newVersionText 
 */
  handleVersionChange(newVersionText){
    if (newVersionText !== `${this.VERSION_PREFIX_TEXT}${this.state.selectedVersion}`)
    {
      // extract the version number
      let newVersionInt = parseInt(newVersionText.slice(this.VERSION_PREFIX_TEXT.length));
      if (newVersionText === this.EDITABLE_VERSION_TEXT)
      {
        newVersionInt = 0;
      }
      this.setState({ selectedVersion: newVersionInt}, 
        () => this.updateForm(this.state.selectedAgreementId, newVersionInt, true));
    }
  }

  isRefDataDeprecated(refDataId){
    let retValue = false;
    const refDataItem = this.lookupRefData(refDataId);
    if (refDataItem !== null){
      retValue = refDataItem.endsWith(configData.REFDATA_DEPRECATED)
    }
    return retValue;
  }

  /**
   * Renders the agreement table
   * @returns The JSX of the controls
   */
  renderAgreementTable() {
    return (
      <table>
        <tbody>
          <tr valign="top">
            <td width="15%" style={{"fontWeight":"bold"}}>Title</td>
            <td width="40%">{this.state.agreement.title}</td>
            <td width="1%" style={this.setValueColor("validFrom", {"fontWeight":"bold", whiteSpace:"nowrap"})}>Valid From</td>
            <td width="8%" style={this.setValueColor("validFrom", {whiteSpace:"nowrap"})}>{formatDate(this.state.agreement.validFrom)}</td>
            <td width="1%" style={this.setValueColor("terminationDate", {"fontWeight":"bold", whiteSpace:"nowrap"})}>Termination Date</td>
            <td width="8%" style={this.setValueColor("terminationDate", {whiteSpace:"nowrap"})}>{formatDate(this.state.agreement.terminationDate)}</td>
          </tr>
          <tr valign="top">
            <td style={this.setValueColor("rocheAffiliateId", {"fontWeight":"bold"})}>Roche Affiliate</td>
            <td style={this.setValueColor("rocheAffiliateId")}>
              {this.lookupRefData(this.state.agreement.rocheAffiliateId, 
                                  "rocheAffiliateValue" in this.state.agreement?this.state.agreement.rocheAffiliateValue : null)}
            </td>
            <td colSpan="4" style={this.setValueColor("rocheAffiliateId")}>
              <OwcCheckbox disabled={true} checked={this.state.agreement.entitlementDateValidityVaries}>
                <OwcTypography variant="caption">Some data entitlements are valid beyond the agreement termination date</OwcTypography>
              </OwcCheckbox>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }


  /**
   * Renders the agreement table
   * @returns The JSX of the controls
   */
  renderPartiesTable() {
    return (
      <table>
        <tbody>
          <tr valign="top">
            <td width="20%" style={{"fontWeight":"bold"}}>Customer Name</td>
            <td width="20%">{this.state.agreement.customerName}</td>
            <td width="10%" style={this.setValueColor("customerAddress", {"fontWeight":"bold"})}>Address</td>
            <td width="30%" style={this.setValueColor("customerAddress")}>{this.state.agreement.customerAddress}</td>
            <td width="10%" style={this.setValueColor("customerCountry", {"fontWeight":"bold"})}>Country</td>
            <td width="10%" style={this.setValueColor("customerCountry")}>{this.state.agreement.customerCountry}</td>
          </tr>          
          <tr valign="top">
            <td colSpan="1" style={{"fontWeight":"bold"}}>Customer Account Number</td>
            <td colSpan="5">{this.state.agreement.customerAccountNo}</td>
          </tr>
          <tr valign="top">
            <td style={{"fontWeight":"bold"}}>Roche Name</td>
            <td>{this.state.agreement.rocheName}</td>
            <td style={this.setValueColor("rocheAddress", {"fontWeight":"bold"})}>Address</td>
            <td style={this.setValueColor("rocheAddress")}>{this.state.agreement.rocheAddress}</td>
            <td style={this.setValueColor("rocheCountry", {"fontWeight":"bold"})}>Country</td>
            <td style={this.setValueColor("rocheCountry")}>{this.state.agreement.rocheCountry}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  /**
   * Renders the term pairs
   * @returns The JSX of the controls
   */
  renderPairs(entitlementType) {
    if (!this.state.pairsLoaded || !this.state.entitlementsLoaded)
    {
      return;
    }

    const pairList = this.state.pairs.filter(pair =>
      pair.pairingType === entitlementType
    );
    if (pairList.length === 0) {
      return (
        <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px", marginBottom:"0.5em", marginTop:"1em"}}>
          <OwcTypography style={{ fontWeight: "bold", color:"Grey" }}>No {entitlementType}s</OwcTypography>
        </div>
      );
    } else {
      return (
        <OwcTable style={{ display: "block", marginBottom: "1em" }} spacing='compact' height="auto" >
          <OwcTableHeader elevated sticky>
            <OwcTableHeaderCell width="15%" resizable>Data Category</OwcTableHeaderCell>
            <OwcTableHeaderCell width="25%" resizable>{entitlementType}</OwcTableHeaderCell>
            <OwcTableHeaderCell width="60%" resizable>Mapping</OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody>
            {pairList.map(pair => (
              <OwcTableRow key={"DisplayRow" + pair.termPairingNo}>
                {pair.dataCategoryText === null || pair.dataCategoryText.trim() === "" ?
                    <OwcTableCell key={"DisplaydataCategoryCell" + pair.termPairingNo} 
                      style={this.state.selectedVersion === 0?{wordBreak:"break-word", color:"orange"}:{wordBreak:"break-word"}} valign="top">
                        No Value</OwcTableCell>
                  : <OwcTableCell key={"DisplaydataCategoryCell" + pair.termPairingNo} style={{wordBreak:"break-word"}} valign="top">{pair.dataCategoryText}</OwcTableCell>
                }
                {pair.dataUsageText === null || pair.dataUsageText.trim() === "" ?
                    <OwcTableCell key={"DisplaydataUsageCell" + pair.termPairingNo} 
                      style={this.state.selectedVersion === 0?{wordBreak:"break-word", color:"orange"}:{wordBreak:"break-word"}} valign="top">
                          No Value</OwcTableCell>
                  : <OwcTableCell key={"DisplaydataUsageCell" + pair.termPairingNo} style={{wordBreak:"break-word"}} valign="top">{pair.dataUsageText}</OwcTableCell>
                }
                <OwcTableCell key={"DisplayMappingComment" + pair.termPairingNo} style={{wordBreak:"break-word"}} valign="top">
                  {this.renderPairMapping(pair)}
                </OwcTableCell>
              </OwcTableRow>
            ))}
          </OwcTableBody>
          <div slot="header" style={{ alignItems: 'center' }}>
              <label style={{ flexGrow: 1, fontWeight:"bold"}}>{entitlementType}</label>
          </div>
        </OwcTable>
      );
    }
  }

  /**
   * Renders the display of mapped entitlements for a pair
   * @returns The JSX of the controls
   */
  renderPairMapping(pair) {
    if (pair.termPairingNo in this.state.termPairEntitlementMapping) {
      return (
        <table>
          <tbody>
            {this.state.termPairEntitlementMapping[pair.termPairingNo].map((entitlementId, index) => (
              <tr key={"PairEntitlementMappingRow" + pair.termPairingNo + entitlementId}  valign="top">
                {this.renderPairMappingRow(pair, entitlementId, index)}
              </tr>
            ))}
            {pair.notMapped ?
              <tr key={"PairEntitlementMappingRowCannot" + pair.termPairingNo}  valign="top">
                <td key={"PairEntitlementMappingRowCannotText" + pair.termPairingNo} colSpan="4">
                  <OwcTypography style={this.state.selectedVersion===0?{ color: "orange" }: {}}>Also cannot be mapped{pair.notMappedReason ? ", reason: " + pair.notMappedReason : ""}</OwcTypography></td>
              </tr>
              : <></>
            }
          </tbody>
        </table>
      );
    } else {
      if (pair.notMapped) {
        return (
          <OwcTypography>Cannot be mapped{pair.notMappedReason ? ", reason: " + pair.notMappedReason : ""}</OwcTypography>
        );
      } else {
        return (
          <OwcTypography style={this.state.selectedVersion===0?{ color: "orange" }:{}}>Not mapped yet</OwcTypography>
        );
      }
    }
  }

  /**
   * Renders an individual entitlment that maps to a pair
   * @returns The JSX of the controls
   */
  renderPairMappingRow(pair, entitlementIdToShow, index) {

    const entitlement = this.state.entitlements.find(({ entitlementId }) => entitlementId === entitlementIdToShow);
    return (
      <>
        <td key={"PairEntitlementMappingComment" + pair.termPairingNo + entitlementIdToShow} style={{maxWidth:"60%", minWidth:"20%", overflowWrap:"normal"}}>
          {index === 0 ? "is mapped to:" : ""}
        </td>
        <td key={"PairEntitlementMappingDataCategory" + pair.termPairingNo + entitlementIdToShow} style={{maxWidth:"50%", minWidth:"20%", overflowWrap:"break-word"}}>
          {this.lookupRefData(entitlement.dataCategoryCode,
                              "dataCategoryValue" in entitlement?entitlement.dataCategoryValue : null)}
        </td>
        <td>{this.RIGHT_ARROW}</td>
        <td key={"PairEntitlementMappingDataUsage" + pair.termPairingNo + entitlementIdToShow} style={{maxWidth:"50%", minWidth:"30%", overflowWrap:"break-word"}}>
          {this.lookupRefData(entitlement.dataUsageCode,
                              "dataUsageValue" in entitlement?entitlement.dataUsageValue : null)}
        </td>
      </>
    );
  }

  /**
   * Renders the entitlements
   * @returns The JSX of the controls
   */
  renderEntitlements(entitlementType) {
    const entitlementList = this.state.entitlements.filter(entitlement =>
      entitlement.entitlementType === entitlementType
    );
    if (entitlementList === undefined || entitlementList.length === 0) {
      return (
        <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px", marginBottom:"0.5em", marginTop:"1em"}}>
          <OwcTypography style={{ fontWeight: "bold", color: "Grey" }}>No {entitlementType} Entitlements</OwcTypography>
        </div>
      );
    } else {
      return (
        <OwcTable style={{ display: "block", marginBottom: "1em" }} spacing='compact' height="auto" >
          <OwcTableHeader elevated sticky>
            <OwcTableHeaderCell width="15%" resizable>Data Category</OwcTableHeaderCell>
            <OwcTableHeaderCell width="25%" resizable>{entitlementType}</OwcTableHeaderCell>
            <OwcTableHeaderCell width="60%" resizable>Mapping</OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody>
            {entitlementList.map(entitlement => (
              <OwcTableRow key={"EntitlementRow" + entitlement.entitlementId}>
                <OwcTableCell key={"EntitlementCategoryCell" + entitlement.entitlementId} valign="top"
                  style={this.state.selectedVersion === 0 && this.isRefDataDeprecated(entitlement.dataCategoryCode)?
                    {color:"orange",wordBreak:"break-word"}:
                    {wordBreak:"break-word"}} 
                >
                  {this.lookupRefData(entitlement.dataCategoryCode,
                                      "dataCategoryValue" in entitlement?entitlement.dataCategoryValue : null)}
                </OwcTableCell>
                <OwcTableCell key={"EntitlementUsageCell" + entitlement.entitlementId} valign="top"
                  style={this.state.selectedVersion === 0 && this.isRefDataDeprecated(entitlement.dataUsageCode)?
                  {color:"orange",wordBreak:"break-word"}:
                  {wordBreak:"break-word"}} 
                >
                  {this.lookupRefData(entitlement.dataUsageCode,
                                      "dataUsageValue" in entitlement?entitlement.dataUsageValue : null)}
                </OwcTableCell>
                <OwcTableCell key={"EntitlementMappingComment" + entitlement.entitlementId} style={{wordBreak:"break-word"}} valign="top">
                  {this.renderEntitlementMapping(entitlement)}
                </OwcTableCell>
              </OwcTableRow>
            ))}
          </OwcTableBody>
          <div slot="header" style={{ alignItems: 'center' }}>
              <label style={{ flexGrow: 1, fontWeight:"bold"}}>{entitlementType}</label>
          </div>
        </OwcTable>
      );
    }
  }

  /**
   * Renders the display of mapped pairs for a entitlement
   * @returns The JSX of the controls
   */
  renderEntitlementMapping(entitlement) {
    if (entitlement.mappedPairs.length > 0) {
      return (
        <table>
          <tbody>
            {entitlement.mappedPairs.map((termPairingNo, index) => {
              return(
                <tr key={"EntitlementPairMappingRow" + entitlement.termPairingNo + termPairingNo} valign="top">
                  {this.renderEntitlementMappingRow(entitlement, termPairingNo, index)}
                </tr>
                );
              }
            )}
            {this.state.agreement.entitlementDateValidityVaries?
              <tr key={"EntitlementPairMappingDateRow" + entitlement.termPairingNo}
                style={this.checkValidityDates(entitlement.validFrom, entitlement.terminationDate) !== null?
                      {color:"red"} :
                      {}
                }>
                <td key={"EntitlementPairMappingDateCell1_" + entitlement.termPairingNo}>Valid from: </td>
                <td key={"EntitlementPairMappingDateCell2_" + entitlement.termPairingNo}>
                  {entitlement.validFrom !== null ? formatDate(entitlement.validFrom): "undefined date"}
                  </td>
                <td key={"EntitlementPairMappingDateCell3_" + entitlement.termPairingNo}></td>
                <td key={"EntitlementPairMappingDateCell4_" + entitlement.termPairingNo}>
                  To: {entitlement.terminationDate !== null ? formatDate(entitlement.terminationDate): "undefined date"}
                </td>
              </tr>
            : null}
          </tbody>
        </table>
      );
    } else {
      return (
        <div style={{}}
          key={"EntitlementPairMappingSection" + entitlement.entitlementId}>
          <OwcTypography style={this.state.selectedVersion===0?{ color: "orange" }:{}}>Not mapped</OwcTypography>
          {this.state.agreement.entitlementDateValidityVaries?
              <OwcTypography key={"EntitlementPairMappingDate" + entitlement.termPairingNo}>
                <div style={{height:"0.35em"}}/>
                Valid from: {entitlement.validFrom !== null ? formatDate(entitlement.validFrom): "undefined date"}
                &nbsp;To: {entitlement.terminationDate !== null ? formatDate(entitlement.terminationDate): "undefined date"}
              </OwcTypography>
          : ""}
        </div>
      );
    }
  }

  /**
   * Renders an individual pair that maps to an entitlement
   * @returns The JSX of the controls
   */
  renderEntitlementMappingRow(entitlement, termPairingNoToShow, index) {

    const termPair = this.state.pairs.find(({ termPairingNo }) => termPairingNo === termPairingNoToShow);
    if (termPair !== undefined)
    {
      return (
        <>
          <td key={"EntitlementPairMappingComment" + entitlement.entitlementId + termPairingNoToShow} style={{maxWidth:"40%", minWidth:"20%", overflowWrap:"normal"}}>
            {index === 0 ? "is dervied from:" : ""}
          </td>
          <td key={"EntitlementPairMappingDataCategory" + entitlement.entitlementId + termPairingNoToShow} style={{maxWidth:"50%", minWidth:"20%", overflowWrap:"break-word"}}>
            {termPair.dataCategoryText}
          </td>
          <td>&#8594;</td>
          <td key={"EntitlementPairMappingDataUsage" + entitlement.entitlementId + termPairingNoToShow} style={{maxWidth:"50%", minWidth:"30%", overflowWrap:"break-word"}}>
            {termPair.dataUsageText}
          </td>
        </>
      );
    }
  }

renderSubmitButton() {
  let messageColour = "black";
  if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
    messageColour = "red";
  };
  if (this.props.dataSteward){
    return (
      <React.Fragment>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
          <OwcButton style={{ width: "fit-content" }}
            onclick={() => this.handleSubmitClick()}
            disabled={((this.state.submissionState === "Publishing ...") || (this.state.errors.length !== 0)) || (this.state.selectedVersion !== 0) ? true : false}
          >
          {this.state.submissionState === "Publishing ..." ? this.state.submissionState : "Publish version " + (this.state.maxVersion + 1)}
        </OwcButton>
        </div>
        <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
          {this.state.submissionState === "Publishing ..." ? "" : this.state.submissionState}
        </OwcTypography>
      </React.Fragment>
    );
  }
  else {
    return (
      <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right"}}>
        Only Data Stewards can publish new versions
      </OwcTypography>
    );
  }
}

  /**
   * Renders the validation errors and warnings
   * @returns The JSX of the controls
   */
  renderValidation()
  {
    if (this.state.selectedVersion === 0)
    {
      return (
        <>
          <OwcTypography style={{ paddingTop: "5em" }} variant="title6">Validation Results</OwcTypography>
          <hr />
          {this.state.errors.length>0?
            <OwcTable style={{ display: "block", marginBottom: "0.5em" }} spacing='compact' height="auto" >
              <OwcTableHeader elevated sticky>
                <OwcTableHeaderCell width="20%" resizable>Tab to make changes</OwcTableHeaderCell>
                <OwcTableHeaderCell width="80%" resizable>Error</OwcTableHeaderCell>
              </OwcTableHeader>
              <OwcTableBody>
                {this.state.errors.map((error, index) => (
                  <OwcTableRow key={"Row" + index}>
                    <OwcTableCell key={"Tab" + index} style={{wordBreak:"break-word"}} valign="top">{error.tab}</OwcTableCell>
                    <OwcTableCell key={"Text" + index} style={{wordBreak:"break-word"}} valign="top">{error.error}</OwcTableCell>
                  </OwcTableRow>
                ))}
              </OwcTableBody>
              <div slot="header" style={{ alignItems: 'center' }}>
                <label style={{ flexGrow: 1, fontWeight:"bold", color:"red"}}>Errors preventing publishing a new version</label>
              </div>
            </OwcTable>
          : <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px"}}>
              <OwcTypography style={{fontWeight:"bold", color:"Grey"}}>No Errors</OwcTypography>
            </div>
          }
          <br />
          {this.state.warnings.length>0?
          <OwcTable style={{ display: "block", marginBottom: "0.5em" }} spacing='compact' height="auto" >
            <OwcTableHeader elevated sticky>
              <OwcTableHeaderCell width="20%" resizable>Tab to make changes</OwcTableHeaderCell>
              <OwcTableHeaderCell width="80%" resizable>Warning</OwcTableHeaderCell>
            </OwcTableHeader>
            <OwcTableBody>
              {this.state.warnings.map((warning, index) => (
                <OwcTableRow key={"Row" + index}>
                  <OwcTableCell key={"Tab" + index} style={{wordBreak:"break-word"}} valign="top">{warning.tab}</OwcTableCell>
                  <OwcTableCell key={"Text" + index} style={{wordBreak:"break-word"}} valign="top">{warning.warning}</OwcTableCell>
                </OwcTableRow>
              ))}
            </OwcTableBody>
            <div slot="header" style={{ alignItems: 'center' }}>
              <label style={{ flexGrow: 1, fontWeight:"bold", color: "orange"}}>Warnings (will not prevent publishing a new version)</label>
            </div>
          </OwcTable>
          : <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px"}}>
              <OwcTypography style={{fontWeight:"bold", color:"Grey"}}>No Warnings</OwcTypography>
            </div>
          }
          <br />
          </>
      );
    }
  }


  /**
   * Renders the mapping comments if there are any
   * @returns The JSX of the controls
   */
  renderMappingComments(){
    if (this.state.agreement.comment) {
      return(
        <>
          <OwcTypography style={{fontWeight:"bold"}}>Mapping Comments</OwcTypography>
          <OwcTypography>{this.state.agreement.comment}</OwcTypography>
        </>
      );
    }
  }

  /**
   * Renders the form
   * @returns The JSX of the controls
   */
  render() {
    if (this.state.selectedAgreementLoading === true) {
      return (
        <div key="dataAgreementContentDiv" 
          style={{opacity:"0.5", backgroundColor:"black",
          height:"100%", width:"100%", 
          padding: "50%"}}>
          <owc-progress-spinner />
          <OwcTypography variant="title6">Loading ...</OwcTypography>
        </div>);
    } else {
      if (this.state.selectedAgreementId === null) {
        return (<OwcTypography variant="title6">Select an agreement to view</OwcTypography>);
      } else {
        return (
          <div key="dataAgreementContentDiv" >
            <table width="100%"><tbody><tr>
              <td align="left">
                <OwcTypography style={{ paddingTop: "5em" }} variant="title6">Agreement</OwcTypography>
              </td>
              <td style={{width: "1%", whiteSpace: "nowrap"}}>
                <OwcTypography style={{ marginTop: "0.3em", fontWeight: "bold" }}>
                  Version
                </OwcTypography>
              </td>
              <td align="right" style={{width: "1%", whiteSpace: "nowrap"}}>
                <select 
                  value={this.state.selectedVersion === 0? 
                    this.EDITABLE_VERSION_TEXT
                    : this.VERSION_PREFIX_TEXT + this.state.selectedVersion}
                  onChange={(ev) => { this.handleVersionChange(ev.target.value)}}
                >
                  <option>{this.EDITABLE_VERSION_TEXT}</option>
                  {this.state.versions.slice(0).reverse().map(version => (
                    <option key={version.versionNo} >{this.VERSION_PREFIX_TEXT}{version.versionNo}</option>
                  ))}
                </select>
              </td>
            </tr>            
            {this.state.selectedVersion === 0 
              ? <></> 
              : <tr> 
                <td> &nbsp;</td>
                <td align="right" style={{width: "1%", whiteSpace: "nowrap", fontWeight: "bold"}}>Published on</td>
                <td style={{width: "1%", whiteSpace: "nowrap"}}>{formatDate(this.state.agreement.versionDateCreated, true)}</td>
                </tr>
            }
            </tbody></table>

            <hr />
            {this.renderAgreementTable()}

            <br />
            <OwcTypography style={{ paddingTop: "5em" }} variant="title6">Parties</OwcTypography>
            <hr />
            {this.renderPartiesTable()}
            <br />
            <OwcTypography style={{ paddingTop: "5em" }} variant="title6">Data Clause{(this.state.agreement.dataClauses) && (this.state.agreement.dataClauses.length === 1)
              ? ""
              : "s"}
            </OwcTypography>
            <hr />
            <DataClauses dataClauses={this.state.agreement.dataClauses} />

            <OwcTypography style={{ paddingTop: "5em" }} variant="title6">Mapped processing actions and data uses to harmonized entitlements</OwcTypography>
            <hr />
            {this.renderPairs(configData.ENTITLEMENT_PROCESSING_ACTION)}
            {this.renderPairs(configData.ENTITLEMENT_DATA_USE)}

            <br />
            <OwcTypography style={{ paddingTop: "5em" }} variant="title6">Entitlements derived from processing actions and data uses</OwcTypography>
            <hr />
            {this.renderEntitlements(configData.ENTITLEMENT_PROCESSING_ACTION)}
            {this.renderEntitlements(configData.ENTITLEMENT_DATA_USE)}
            {this.renderMappingComments()}
            <br />
            {this.renderValidation()}
            {this.renderSubmitButton()}
          </div>
        );
      }
    }
  }
}


export default ReviewAndPublish;
